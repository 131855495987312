import styled from "styled-components";
import {NavLink} from "react-router-dom";
import iconChevronDown from "assets/images/icons/chevronDown.svg";
import iconChevronUp from "assets/images/icons/chevronUp.svg";

export const Tabs = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	box-shadow: 0 0 20px 5px #9090901f;
	padding: 0 30px;

	@media (max-width: 640px) {
		padding: 0 20px;
	}
`;

export const TabItemContent = styled.div``;

export const TabItem = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const Tab = styled(NavLink)`
	flex: 1;
	display: flex;
	align-items: center;
	border: none;
	text-align: center;
	padding: 20px 0;
	border-bottom: 1px solid #e9e9e9;
	color: #010101;
	font-size: 24px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 29px;

	background: #fff url(${iconChevronDown}) no-repeat right 20px center;

	&.active {
		background-image: url(${iconChevronUp});
	}

	@media (max-width: 640px) {
		font-size: 18px;
	}
`;
