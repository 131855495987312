import React from "react";
import alertIcon from "assets/images/icons/alert.png";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {
	ButtonsBox,
	CloseButton,
	Img,
	Modal,
	ModalContainer,
	ModalContent,
	Text,
	Title,
} from "components/Modals/common";
import {PrimaryButton, SecondaryButton, Exist} from "components";
import {useSelector} from "react-redux";
import {isNavBlocking} from "modules/selectors";
import {useBlockNavigation} from "modules/utils";

const PromptModal: React.FC<{isBlocking: boolean}> = ({isBlocking}) => {
	const [showPrompt, confirmNavigation, cancelNavigation] = useBlockNavigation(isBlocking);

	if (!showPrompt) {
		return null;
	}

	return (
		<ModalContainer>
			<Modal>
				<CloseButton onClick={cancelNavigation}>
					<CloseIcon />
				</CloseButton>
				<ModalContent>
					<Img src={alertIcon} alt="" />
					<Title>Enter Tips</Title>
					<Text>
						You have not entered a tip for all games! <br /> Don’t forget to select all
						tips.
					</Text>
					<ButtonsBox>
						<SecondaryButton onClick={confirmNavigation}>Yes leave</SecondaryButton>
						<PrimaryButton onClick={cancelNavigation}>Edit Picks</PrimaryButton>
					</ButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};

export const EnterTipsModal: React.FC = () => {
	const isBlocking = useSelector(isNavBlocking);

	return (
		<Exist when={isBlocking}>
			<PromptModal isBlocking={isBlocking} />
		</Exist>
	);
};
