import React from "react";
import styled from "styled-components";
import {TournamentStatusType} from "modules";

const Message = styled.div`
	color: #fff;
	height: 19px;
	width: 95px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	background-color: #46b413;
	font-size: 10px;
	font-weight: bold;
	@media (min-width: 769px) {
		height: 20px;
		width: 120px;
		font-size: 12px;
	}
`;

export const LiveMatchLabel: React.FC<{status: TournamentStatusType}> = ({status}) => {
	if (status !== TournamentStatusType.Playing) {
		return null;
	}
	return <Message>MATCH IS LIVE</Message>;
};
