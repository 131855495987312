import styled from "styled-components";

export const PureButton = styled.button`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

export * from "./BaseButton";
export * from "./PrimaryButton";
export * from "./SecondaryButton";
export * from "./TertiaryButton";
export * from "./ShareButton";
export * from "./WarningButton";
export * from "./LoadMoreButton";
