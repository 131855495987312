import {call, put} from "typed-redux-saga";

import Api from "modules/utils/Api";
import {userGeoLocationAdsFailed, userGeoLocationAdsSuccess} from "modules/actions/ads";

export const userGeolocationSaga = function* () {
	try {
		const response = yield* call(Api.User.geoLocationAds);
		yield* put(userGeoLocationAdsSuccess(response.success));
	} catch (err) {
		yield* put(userGeoLocationAdsFailed());
	}
};
