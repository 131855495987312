import {createTheme} from "@mui/material";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const fontFamily = ["Proxima Nova", "Arial", "sans-serif"].join(",");

const themeObject = {
	colors: {
		primary: "#062DB0",
		secondary: "#13191D",
		grey: "#4B4B4B",
		warning: "#A82727",
		error: "#A82727",
	},
	text: {
		primary: "#ffffff",
		secondary: "#BFBFBF",
		tertiary: "#010101",
	},
	border: {
		primary: "#ffffff",
		secondary: "#B8B8B8",
	},
	logo: {
		bgc: "#9c5e5c",
		text: "#ffffff",
	},
	brand: {
		primary: "#062DB0",
		secondary: "#ffffff",
		disabled: "#B8B8B8",
		primaryText: "#ffffff",
		secondaryText: "#002EB7",
	},
	button: {
		primary: {
			color: "#fff",
			background: "#062DB0",
			borderColor: "#062DB0",
			disabledColor: "#fff",
			disabledBackground: "#9B9B9B",
			disabledBorderColor: "#9B9B9B",
			hoverColor: "#fff",
			hoverBackground: "#003BFF",
			hoverBorderColor: "#003BFF",
			fontWeight: "bold",
		},
		secondary: {
			color: "#062DB0",
			background: "transparent",
			borderColor: "#062DB0",
			disabledColor: "#B8B8B8",
			disabledBackground: "transparent",
			disabledBorderColor: "#D1D1D1",
			hoverColor: "#003BFF",
			hoverBackground: "transparent",
			hoverBorderColor: "#003CFF",
			fontWeight: 500,
		},
		tertiary: {
			color: "#002EB7",
			background: "transparent",
			borderColor: "transparent",
			disabledColor: "#434343",
			disabledBackground: "transparent",
			disabledBorderColor: "transparent",
			hoverColor: "#003EFF",
			hoverBackground: "transparent",
			hoverBorderColor: "transparent",
			fontWeight: "bold",
		},
		warning: {
			color: "#ffffff",
			background: "#A82727",
			borderColor: "#A82727",
			disabledColor: "#ffffff",
			disabledBackground: "#FF434333",
			disabledBorderColor: "#FF434333",
			hoverColor: "#ffffff",
			hoverBackground: "#FF4343B3",
			hoverBorderColor: "#FF4343B3",
			fontWeight: "regular",
		},
		warningOutlined: {
			color: "#A82727",
			background: "transparent",
			borderColor: "#A82727",
			disabledColor: "#FF434377",
			disabledBackground: "transparent",
			disabledBorderColor: "#FF434377",
			hoverColor: "#FF4343B3",
			hoverBackground: "transparent",
			hoverBorderColor: "#FF4343B3",
			fontWeight: "regular",
		},
	},
	form: {
		link: {
			primary: "#0D1D9A",
		},
		border: {
			primary: "#2C2C2C",
		},
	},
	menuDrawer: {
		primary: "#062DB0",
		secondary: "#ffffff",
	},
	table: {
		head: {
			primary: "#000000",
			primaryText: "#ffffff",
		},
		body: {
			primary: "#ffffff",
			secondary: "#F5F5F5",
			primaryText: "#010101",
		},
	},
	typography: {
		fontFamily: fontFamily,
	},
	// components: {MuiTextField: {fontFamily}}
};

export const theme = createTheme(themeObject);
type ThemeInterface = typeof themeObject;

declare module "styled-components" {
	// Allows change type of  theme to avoid any
	/* eslint-disable @typescript-eslint/no-empty-interface */

	// eslint-disable-next-line @typescript-eslint/naming-convention
	export interface DefaultTheme extends ThemeInterface {}
}
