import React, {useCallback, useRef} from "react";
import {
	ButtonsBox,
	CloseButton,
	Img,
	Modal,
	ModalContainer,
	ModalContent,
	Text,
	Title,
} from "components/Modals/common";
import {PrimaryButton} from "components";
import {useDispatch, useSelector} from "react-redux";
import {clearGlobalError} from "modules/actions";
import {getErrorModalState} from "modules/selectors";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import alertIcon from "assets/images/icons/alert.png";
import {useClickOutside} from "modules/utils/hooks/useClickOutside";

export const ErrorModal: React.FC = () => {
	const ref = useRef(null);
	const dispatch = useDispatch();
	const {message, title} = useSelector(getErrorModalState);

	const closeHandler = useCallback(() => {
		dispatch(clearGlobalError());
	}, [dispatch]);

	useClickOutside(ref, closeHandler);

	if (!message) {
		return null;
	}

	return (
		<ModalContainer>
			<Modal ref={ref}>
				<CloseButton id="btn-close-error-modal">
					<CloseIcon onClick={closeHandler} />
				</CloseButton>
				<ModalContent>
					<Img src={alertIcon} alt="" />
					{title && <Title>{title}</Title>}
					<Text>{message}</Text>
					<ButtonsBox>
						<PrimaryButton onClick={closeHandler}>OK</PrimaryButton>
					</ButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
