import {SagaIterator} from "redux-saga";
import {all, takeEvery, takeLatest} from "redux-saga/effects";
import {
	createdUpdateLeague,
	createLeague,
	deleteLeague,
	deleteLeagueUser,
	fetchChecksumsJSON,
	getFaqContent,
	getHelpContent,
	getLeagueRankings,
	getLeagues,
	getLeaguesForJoin,
	getLeagueUsers,
	getRankings,
	globalError,
	inviteUsersToCreateLeague,
	inviteUsersToLeague,
	joinLeague,
	joinLeagueWithRedirect,
	leaveLeague,
	postAutoPick,
	postContact,
	postSavePicks,
	requestCountriesJSON,
	requestCurrentUser,
	requestGameData,
	requestGameJsonData,
	requestLadderJson,
	requestResetPassword,
	requestRoundsJSON,
	requestRoundStatsJSON,
	requestSnapshotByRoundId,
	requestSquadsJSON,
	resetPassword,
	updateLeague,
	userLogin,
	userLogout,
	userRegister,
	userUpdate,
	userPreregister,
	userCheckTeamname,
} from "modules/actions";
import {errorsManagerSaga} from "modules/sagas/errors";
import {
	getCurrentUserSaga,
	loginSaga,
	logoutSaga,
	registerSaga,
	requestResetPasswordSaga,
	resetPasswordSaga,
	userUpdateSaga,
	preregisterSaga,
	userReactivateSaga,
	userAcceptTCSaga,
	userCheckTeamnameSaga,
} from "modules/sagas/user";
import {
	fetchChecksumsJSONSaga,
	fetchFaqSaga,
	fetchHelpContentSaga,
	requestCountriesJSONSaga,
	requestLadderJsonSaga,
	requestRoundsJSONSaga,
	requestRoundStatsJSONSaga,
	requestSquadsJSONSaga,
} from "./json";
import {
	postAutoPickSaga,
	postSavePicksSaga,
	requestGameDataSaga,
	requestGameJsonDataSaga,
	requestSnapshotByRoundIdSaga,
} from "modules/sagas/game";
import {postContactSaga} from "modules/sagas/contact";
import {
	createdUpdateLeagueSaga,
	createLeagueSaga,
	getLeaguesForJoinSaga,
	getLeaguesSaga,
	inviteUsersToCreateLeagueSaga,
	joinLeagueSaga,
	joinLeagueWithRedirectSaga,
} from "modules/sagas/leagues";
import {getLeague} from "modules/actions/league";
import {
	deleteLeagueSaga,
	deleteLeagueUserSaga,
	getLeagueRankingsSaga,
	getLeagueSaga,
	getLeagueUsersSaga,
	inviteUsersToLeagueSaga,
	leaveLeagueSaga,
	updateLeagueSaga,
} from "./league";
import {getRankingsSaga} from "./rankings";
import {hideAcceptTCModal, hideReactivateModal} from "modules/actions/modals";
import {userGeoLocationAds} from "modules/actions/ads";
import {userGeolocationSaga} from "./ads";

/**
 * Root saga that connect sagas with actions.
 */
export const rootSaga = function* (): SagaIterator {
	yield all([
		/**
		 * JSON
		 */
		takeLatest(requestRoundsJSON, requestRoundsJSONSaga),
		takeLatest(requestCountriesJSON, requestCountriesJSONSaga),
		takeLatest(requestSquadsJSON, requestSquadsJSONSaga),
		takeLatest(requestRoundStatsJSON, requestRoundStatsJSONSaga),
		takeLatest(fetchChecksumsJSON, fetchChecksumsJSONSaga),
		takeLatest(getHelpContent, fetchHelpContentSaga),
		takeLatest(getFaqContent, fetchFaqSaga),
		takeLatest(requestLadderJson, requestLadderJsonSaga),

		/**
		 * Common
		 */
		takeLatest(globalError, errorsManagerSaga),

		/**
		 * Auth
		 */
		takeLatest(userLogin, loginSaga),
		takeLatest(userRegister, registerSaga),
		takeLatest(userLogout, logoutSaga),
		takeLatest(userUpdate, userUpdateSaga),
		takeLatest(hideReactivateModal, userReactivateSaga),
		takeLatest(hideAcceptTCModal, userAcceptTCSaga),
		takeLatest(requestResetPassword, requestResetPasswordSaga),
		takeLatest(resetPassword, resetPasswordSaga),
		takeLatest(userPreregister, preregisterSaga),

		/**
		 * User
		 */
		takeLatest(requestCurrentUser, getCurrentUserSaga),
		takeLatest(userCheckTeamname, userCheckTeamnameSaga),
		takeLatest(userGeoLocationAds, userGeolocationSaga),
		/**
		 * Game
		 */
		takeLatest(requestGameJsonData, requestGameJsonDataSaga),
		takeLatest(requestGameData, requestGameDataSaga),
		takeLatest(postAutoPick, postAutoPickSaga),
		takeLatest(postSavePicks, postSavePicksSaga),
		takeLatest(requestSnapshotByRoundId, requestSnapshotByRoundIdSaga),

		/**
		 * Contact
		 */
		takeLatest(postContact, postContactSaga),

		/**
		 * Leagues
		 */
		takeLatest(getLeagues, getLeaguesSaga),
		takeLatest(getLeaguesForJoin, getLeaguesForJoinSaga),
		takeLatest(createLeague, createLeagueSaga),
		takeLatest(inviteUsersToCreateLeague, inviteUsersToCreateLeagueSaga),
		takeLatest(createdUpdateLeague, createdUpdateLeagueSaga),
		takeEvery(joinLeague, joinLeagueSaga),
		takeLatest(joinLeagueWithRedirect, joinLeagueWithRedirectSaga),
		takeLatest(getLeagueUsers, getLeagueUsersSaga),

		/**
		 * League
		 */
		takeLatest(getLeague, getLeagueSaga),
		takeLatest(updateLeague, updateLeagueSaga),
		takeLatest(deleteLeague, deleteLeagueSaga),
		takeLatest(leaveLeague, leaveLeagueSaga),
		takeLatest(getLeagueRankings, getLeagueRankingsSaga),
		takeLatest(inviteUsersToLeague, inviteUsersToLeagueSaga),
		takeLatest(deleteLeagueUser, deleteLeagueUserSaga),

		/**
		 * Rankings
		 */
		takeLatest(getRankings, getRankingsSaga),
	]);
};

export * from "./errors";
export * from "./json";
export * from "./liveScoring";
export * from "./user";
export * from "./game";
export * from "./contact";
export * from "./leagues";
export * from "./league";
export * from "./rankings";
export * from "./ads";
