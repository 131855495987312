import React from "react";
import TippingLogo from "assets/images/logo2024client.png";
import TippingResponsiveLogo from "assets/images/logoResponsive2024client.png";
import {isMobile} from "react-device-detect";
import styled from "styled-components";
import {Link} from "react-router-dom";

const LogoWrapper = styled(Link)`
	display: flex;
	align-items: center;
	svg {
		height: 48px;
	}
`;

const TippingLogoImg = styled.img`
	width: 137px;
`;

const TippingResponsiveLogoImg = styled.img`
	width: 40px;
`;

export const Logo: React.FC = () => {
	return (
		<LogoWrapper to="/predictor">
			{!isMobile ? (
				<TippingLogoImg src={TippingLogo} alt="logo" />
			) : (
				<TippingResponsiveLogoImg src={TippingResponsiveLogo} alt="logo" />
			)}
		</LogoWrapper>
	);
};
