import styled from "styled-components";

export const Title = styled.h1`
	font-family: Kino, sans-serif;
	font-weight: 400;
	display: block;
	font-size: 22px;

	@media (min-width: 769px) {
		margin-top: 30px;
		font-size: 28px;
	}
`;
