import {createAction} from "redux-act";
import {IError} from "modules/types";

export interface IShowGlobalError extends IError {
	title?: string;
}

export const globalError = createAction<Error | string>();
export const showGlobalError = createAction<IShowGlobalError>();
export const clearGlobalError = createAction();
