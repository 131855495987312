import React, {useCallback, useState} from "react";
import {
	ButtonsBox,
	Modal,
	ModalContainer,
	ModalContent,
	Text,
	Title,
} from "components/Modals/common";
import {useDispatch, useSelector} from "react-redux";
import {PrimaryButton} from "components";
import {hideReactivateModal} from "modules/actions/modals";
import {getIsOpenReactivateModal} from "modules/selectors";
import styled from "styled-components";
import {Checkbox, FormControlLabel} from "@mui/material";

const FormControlLabelStyled = styled(FormControlLabel)`
	align-self: flex-start;
	margin-bottom: 15px;
`;

const ReactivateTitle = styled(Title)`
	line-height: inherit;
`;

const Description = styled(Text)`
	margin-bottom: 10px;
`;

const ReactivateButton = styled(PrimaryButton)`
	background-color: #001fd6;
`;

const Label = styled.p`
	padding-left: 3px;
	font-size: 14px;
	// color: #001fd6;
	text-align: left;
	font-family: "Proxima Nova", sans-serif;
`;

const ReactivateLink = styled.a`
	font-size: 14px;
	text-decoration: underline;
	color: #343232;
`;

const ReactivateButtonsBox = styled(ButtonsBox)`
	margin-top: 15px;
`;

const CheckoutLabel: React.FC = () => {
	return (
		<Label>
			I have read and accepted the Super Rugby Pacific{" "}
			<ReactivateLink
				href="https://tipping.super.rugby/help/terms"
				target="_blank"
				rel="noreferrer">
				Terms and Conditions
			</ReactivateLink>
			*
		</Label>
	);
};

const CheckboxMarketingLabel = () => {
	return (
		<Label>
			I’d like to hear about special offers, tickets, events, news and more from New Zealand
			Rugby Commercial (NZRC). I have read and I accept the{" "}
			<ReactivateLink
				href="https://www.allblacks.com/privacy-policy/"
				target="_blank"
				rel="noreferrer">
				NZRC Privacy Statement
			</ReactivateLink>{" "}
			acknowledging my personal information will be managed in accordance with that statement.
		</Label>
	);
};
const CheckboxPrivacyLabel = () => {
	return (
		<Label>
			I would like to receive offers and marketing communications from Rugby Australia and I
			have read and accepted the{" "}
			<ReactivateLink href="https://australia.rugby/privacy">
				Rugby Australia Privacy Policy
			</ReactivateLink>
			.
		</Label>
	);
};

const checkBoxStyle = {
	"&.Mui-checked": {
		color: "#001fd6",
	},
};

export const ReactivateModal: React.FC = () => {
	const [form, updateForm] = useState({terms: false, NZRUOptin: false, rugbyAuOptin: false});
	const [isDisabled, setDisableButton] = useState(true);

	const dispatch = useDispatch();
	const open = useSelector(getIsOpenReactivateModal);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {checked, name} = e.currentTarget;

		if (name === "terms") {
			setDisableButton(!checked);
		}

		updateForm({
			...form,
			[name]: checked,
		});
	};

	const closeHandler = useCallback(() => {
		dispatch(
			hideReactivateModal({
				NZRUOptin: form.NZRUOptin ? 1 : 0,
				rugbyAuOptin: form.rugbyAuOptin ? 1 : 0,
				acceptTerms: 1,
			})
		);
	}, [dispatch, form]);

	if (!open) return null;

	return (
		<ModalContainer>
			<Modal>
				<ModalContent>
					<ReactivateTitle>
						REACTIVATE YOUR SUPER RUGBY PACIFIC TIPPING ACCOUNT
					</ReactivateTitle>
					<Description>
						Welcome back! As you have previously registered for Super Rugby Pacific
						tipping you will be using the same account, please accept the Terms and
						Conditions below to continue.
					</Description>
					<FormControlLabelStyled
						control={
							<Checkbox
								name="NZRUOptin"
								onChange={handleOnChange}
								sx={checkBoxStyle}
							/>
						}
						label={<CheckboxMarketingLabel />}
					/>
					<FormControlLabelStyled
						control={
							<Checkbox
								name="rugbyAuOptin"
								onChange={handleOnChange}
								sx={checkBoxStyle}
							/>
						}
						label={<CheckboxPrivacyLabel />}
					/>
					<FormControlLabelStyled
						control={
							<Checkbox name="terms" onChange={handleOnChange} sx={checkBoxStyle} />
						}
						label={<CheckoutLabel />}
					/>
					<ReactivateButtonsBox>
						<ReactivateButton disabled={isDisabled} onClick={closeHandler}>
							REACTIVATE{" "}
						</ReactivateButton>
					</ReactivateButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
