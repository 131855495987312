import {getRankings, getRankingsFailed, getRankingsSuccess} from "modules/actions";
import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils";

export const getRankingsSaga = function* ({payload}: ReturnType<typeof getRankings>) {
	try {
		const result = yield* call(Api.Rankings.get, payload);

		yield* put(getRankingsSuccess(result.success));
	} catch (err) {
		yield* put(getRankingsFailed());
	}
};
