import React, {Fragment, useEffect} from "react";
import DefaultDrawer from "@mui/material/Drawer";
import {useWindowSize} from "modules/utils";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import {Logo} from "components/Header/Logo";
import {IconButton} from "@mui/material";
import {links, NavList, noAuthLinks} from "./NavList";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";

const Drawer = styled(DefaultDrawer)`
	svg {
		color: #ffffff;
	}
`;

const Menu = styled.div`
	color: ${({theme}) => theme.menuDrawer.secondary};
	background-color: ${({theme}) => theme.menuDrawer.primary};
	height: 100%;
	width: 100vw;
	padding: 6px 0 0;
	display: flex;
	flex-direction: column;
`;

const Head = styled.div`
	display: flex;
	padding: 0 20px 0 10px;
`;

const IconContainer = styled(IconButton)`
	&& {
		margin-left: 0;
	}
`;

const LogoutLink = styled(Link)`
	width: 100%;
	display: block;
	background-color: #000000;
	margin-top: auto;
	padding: 20px 25px 35px 20px;
`;

interface INavigation {
	isOpen: boolean;
	closeHandler: () => void;
}

export const Navigation: React.FC<INavigation> = ({isOpen, closeHandler}) => {
	const size = useWindowSize();
	const isAuth = useSelector(isLoggedIn);

	useEffect(() => {
		if (size.width >= 768) {
			closeHandler();
		}
	}, [size.width, closeHandler]);

	return (
		<Drawer anchor="left" open={isOpen} onClose={closeHandler}>
			<Menu>
				<Head onClick={closeHandler}>
					<IconContainer>
						<CloseIcon />
					</IconContainer>
					<Logo />
				</Head>
				{isAuth ? (
					<Fragment>
						<NavList closeHandler={closeHandler} links={links} />
						<LogoutLink to="/logout" onClick={closeHandler}>
							Log Out
						</LogoutLink>
					</Fragment>
				) : (
					<Fragment>
						<NavList closeHandler={closeHandler} links={noAuthLinks} />
					</Fragment>
				)}
			</Menu>
		</Drawer>
	);
};
