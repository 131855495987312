import {createReducer} from "redux-act";
import {RequestState, SaveButtonType, TournamentStatusType} from "modules/enums";
import {
	clearPicks,
	ITournament,
	postAutoPick,
	postAutoPickSuccess,
	postSavePicks,
	postSavePicksSuccess,
	requestGameData,
	requestGameDataFailed,
	requestGameDataSuccess,
	requestGameJsonData,
	requestGameJsonDataSuccess,
	requestPredictionByRoundIdSuccess,
	requestSnapshotByRoundIdSuccess,
	updatePick,
	updateSavePickButtonState,
	updateMarginPick,
} from "modules/actions";
import {IDictionary} from "modules/types";
import {pickBy} from "lodash";

export interface IPrediction {
	tournament: number;
	squad: number;
	squadName?: string;
	margin: number | null;
	isCorrect?: boolean;
}

export interface ISnapshot {
	roundStatus: string;
	round: number | null;
	roundPoints: number | null;
	maxRoundPoints: number | null;
	overallPoints: number | null;
	overallRank: number | null;
	maxOverallPoints: number | null;
	maxRank: number | null;
}

export interface IGame {
	dataRequestJsonState: RequestState;
	dataRequestState: RequestState;
	predictionsById: IDictionary<IPrediction>;
	pickState: SaveButtonType;
	snapshot: ISnapshot;
}

const initial: IGame = {
	dataRequestJsonState: RequestState.IDLE,
	dataRequestState: RequestState.IDLE,
	pickState: SaveButtonType.Disabled,
	predictionsById: {},
	snapshot: {
		roundStatus: "",
		roundPoints: null,
		overallPoints: null,
		overallRank: null,
		maxOverallPoints: null,
		maxRank: null,
		maxRoundPoints: null,
		round: null,
	},
};

const addPredictions = (state: IGame, predictions: IDictionary<IPrediction>) => ({
	...state,
	predictionsById: predictions,
});

const updatePredictions = (state: IGame, predictions: IDictionary<IPrediction>) => ({
	...state,
	predictionsById: predictions,
	pickState: SaveButtonType.Enabled,
});

const updateMarginPredictions = (state: IGame, predictions: IDictionary<IPrediction>) => ({
	...state,
	predictionsById: predictions,
});

const setSaving = (state: IGame) => ({
	...state,
	pickState: SaveButtonType.Saving,
});

const onGameDataRequested = (state: IGame): IGame => ({
	...state,
	dataRequestState: RequestState.Requested,
	snapshot: {
		roundStatus: "",
		roundPoints: null,
		overallPoints: null,
		overallRank: null,
		maxOverallPoints: null,
		maxRank: null,
		maxRoundPoints: null,
		round: null,
	},
});

const onGameDataSuccess = (state: IGame): IGame => ({
	...state,
	dataRequestState: RequestState.Received,
});

const sonGameDataFailed = (state: IGame): IGame => ({
	...state,
	dataRequestState: RequestState.Received,
});

export const game = createReducer({}, initial)
	.on(requestGameJsonData, (state) => ({
		...state,
		dataRequestJsonState: RequestState.Requested,
	}))
	.on(requestGameJsonDataSuccess, (state) => ({
		...state,
		dataRequestJsonState: RequestState.Received,
	}))
	.on(updateSavePickButtonState, (state, payload) => ({
		...state,
		pickState: payload,
	}))
	.on(requestGameData, onGameDataRequested)
	.on(requestGameDataSuccess, onGameDataSuccess)
	.on(requestGameDataFailed, sonGameDataFailed)
	.on(postAutoPick, setSaving)
	.on(postSavePicks, setSaving)
	.on(requestPredictionByRoundIdSuccess, addPredictions)
	.on(postAutoPickSuccess, addPredictions)
	.on(updatePick, updatePredictions)
	.on(updateMarginPick, updateMarginPredictions)
	.on(postSavePicksSuccess, addPredictions)
	.on(requestSnapshotByRoundIdSuccess, (state, payload) => ({
		...state,
		snapshot: payload,
	}))
	.on(clearPicks, (state, tournaments: ITournament[]) => ({
		...state,
		predictionsById: pickBy(state.predictionsById, (predictions) => {
			const tournament = tournaments.find(
				(tournament) => tournament.id === predictions.tournament
			);

			return tournament?.status !== TournamentStatusType.Scheduled;
		}),
		pickState: SaveButtonType.Disabled,
	}));
