import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Fragment, lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import {BASE_URL} from "modules";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {
	ErrorModal,
	Header,
	InfoModal,
	Layout,
	OffseasonLayout,
	NotAuthOnlyRoute,
	PagePreloader,
	PrivateLandingLayout,
	PrivateRoute,
	Session,
	Footer,
	ReactivateModal,
} from "components";
import {store} from "modules/store";
import {DefaultTheme, ThemeProvider} from "styled-components";
import {theme} from "assets/css/theme";
import {AcceptTCModal} from "components/Modals/AcceptTCModal";

const IS_OFF_SEASON = (process.env.REACT_APP_IS_OFFSEASON || "") === "true";

const IS_GOD_MODE = localStorage.getItem("god_mode") === "true";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://715d3b6652d244daab58e9a26ca9627c@o151969.ingest.sentry.io/6142060",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	allowUrls: [".f2p.media.geniussports.com", "tipping.super.rugby"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
	ignoreErrors: [
		"Non-Error promise rejection captured",
		"Abort due to cancellation of share.",
		"AbortError: Share canceled",
	],
});
const Offseason = lazy(() => import("pages/Offseason"));
const Predictor = lazy(() => import("pages/Predictor"));
const CreateLeague = lazy(() => import("pages/leagues/CreateLeague"));
const JoinLeague = lazy(() => import("pages/leagues/JoinLeague"));
const Rankings = lazy(() => import("pages/Rankings"));
const Leagues = lazy(() => import("pages/Leagues"));
const Login = lazy(() => import("pages/Login"));
const Registration = lazy(() => import("pages/Registration"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));
const RecoveryPassword = lazy(() => import("pages/RecoveryPassword"));
const NotFound = lazy(() => import("pages/NotFound"));
const Logout = lazy(() => import("pages/Logout"));
const Help = lazy(() => import("pages/Help"));
const Account = lazy(() => import("pages/Account"));

const AboutLeague = lazy(() => import("pages/league/AboutLeague"));
const InviteLeague = lazy(() => import("pages/league/InviteLeague"));
const ManageLeague = lazy(() => import("pages/league/ManageLeague"));
const StandingsLeague = lazy(() => import("pages/league/StandingsLeague"));
const UpdateLeague = lazy(() => import("pages/league/UpdateLeague"));

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme as unknown as DefaultTheme}>
				<Router basename={BASE_URL}>
					<Suspense fallback={<PagePreloader />}>
						<Session>
							<Header />
							<Routes>
								{!IS_OFF_SEASON || IS_GOD_MODE ? (
									<Fragment>
										<Route element={<NotAuthOnlyRoute />}>
											<Route element={<Layout />}>
												<Route path="/" element={<Login />} />
												<Route path="/login" element={<Login />} />
												<Route
													path="/registration"
													element={<Registration />}
												/>
												<Route
													path="/forgot-password"
													element={<ForgotPassword />}
												/>
												<Route
													path="/password-recovery/:token"
													element={<RecoveryPassword />}
												/>
											</Route>
										</Route>
										<Route element={<PrivateRoute />}>
											<Route element={<PrivateLandingLayout />}>
												<Route path="/predictor">
													<Route index element={<Predictor />} />
													<Route
														path=":roundId"
														element={<Predictor />}
													/>
												</Route>
												<Route path="/leagues">
													<Route index element={<Leagues />} />
													<Route
														path="create"
														element={<CreateLeague />}
													/>
													<Route path="join" element={<JoinLeague />} />
													<Route
														path="join/:code"
														element={<JoinLeague />}
													/>
												</Route>
												<Route path="/league/:leagueId">
													<Route path="about" element={<AboutLeague />} />
													<Route
														path="invite"
														element={<InviteLeague />}
													/>
													<Route
														path="manage"
														element={<ManageLeague />}
													/>
													<Route
														path="standings"
														element={<StandingsLeague />}
													/>
													<Route
														path="update"
														element={<UpdateLeague />}
													/>
												</Route>
												<Route path="/rankings">
													<Route index element={<Rankings />} />
												</Route>
												<Route path="/account/*" element={<Account />} />
												<Route path="/logout" element={<Logout />} />
											</Route>
										</Route>
										<Route path={"/help/*"} element={<Help />} />
										<Route path="*" element={<NotFound />} />
									</Fragment>
								) : (
									<Route>
										<Route element={<OffseasonLayout />}>
											<Route path="/" element={<Offseason />} />
										</Route>
										<Route path={"/help/*"} element={<Help />} />
										<Route path="*" element={<NotFound />} />
									</Route>
								)}
							</Routes>
							<Footer />
						</Session>
					</Suspense>
				</Router>
				<ReactivateModal />
				<ErrorModal />
				<InfoModal />
				<AcceptTCModal />
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
