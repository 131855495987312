import {IUser} from "modules/reducers";
import {ApiError} from "modules/utils";
import {createAction} from "redux-act";

export interface IUserLoginPayload {
	email: string;
	password: string;
}

export interface IUserPreregister {
	email: string;
	name: string;
}

export const userLogin = createAction<IUserLoginPayload>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginFailed = createAction<ApiError>();

export const userPreregister = createAction<IUserPreregister>();
export const userPreregisterSuccess = createAction();
export const userPreregisterFailed = createAction<ApiError>();

export interface IUserUpdatePayload {
	firstName?: string;
	lastName?: string;
	email?: string;
	username?: string;
	isDirectMarketingOptIn?: boolean;
	disabledNotifications?: boolean;
	password?: string;
}

export const userUpdate = createAction<IUserUpdatePayload>();
export const userUpdateSuccess = createAction<IUser>();
export const userUpdateFailed = createAction<ApiError>();

export const markPasswordAsUpdated = createAction();
export const unMarkPasswordAsUpdated = createAction();

export const markUserInfoAsUpdated = createAction();
export const unMarkUserInfoAsUpdated = createAction();

export const userLogout = createAction();
export const userLogoutSuccess = createAction();
export const userLogoutFailed = createAction<ApiError>();

export interface IUserRegisterPayload {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
	gender: string;
	genderNotListed: string;
	username: string;
	favoriteSquadId: number;
	country: string;
	rugbyAuOptin: number;
	NZRUOptin: number;
	birthyear?: number;
	terms: number;
}

export interface IUserCheckTeamnamePayload {
	username: string;
}

export const userRegister = createAction<IUserRegisterPayload>();
export const userRegisterSuccess = createAction<IUser>();
export const userRegisterFailed = createAction<ApiError>();

export const userCheckTeamname = createAction<IUserCheckTeamnamePayload>();
export const userCheckTeamnameSuccess = createAction();
export const userCheckTeamnameFailed = createAction<ApiError>();

export const userClearError = createAction();

export const requestCurrentUser = createAction();
export const requestCurrentUserSuccess = createAction<IUser>();
export const requestCurrentUserFailed = createAction();

export const reactivateCurrentUserSuccess = createAction();
export const reactivateCurrentUserFailed = createAction();

export const acceptTCSuccess = createAction();
export const acceptTCFailed = createAction();

export interface IRequestResetPasswordPayload {
	email: string;
}

export const requestResetPassword = createAction<IRequestResetPasswordPayload>();
export const requestResetPasswordSuccess = createAction();
export const requestResetPasswordFailed = createAction<ApiError>();
export const clearResetPasswordState = createAction();

export interface IResetPasswordPayload {
	password: string;
	token: string;
}

export const resetPassword = createAction<IResetPasswordPayload>();
export const resetPasswordSuccess = createAction();
export const resetPasswordFailed = createAction<ApiError>();
