import {createReducer} from "redux-act";
import {
	fetchChecksumsJSONSuccess,
	ILadder,
	IRound,
	IRoundStat,
	ISquad,
	requestCountriesJSONSuccess,
	requestLadderJsonSuccess,
	requestRoundsJSON,
	requestRoundsJSONFailed,
	requestRoundsJSONSuccess,
	requestRoundStatsJSONSuccess,
	requestSquadsJSONSuccess,
	setFAQContent,
	setHelpContent,
	TCountry,
} from "modules/actions";
import {IDictionary} from "modules/types";
import {RequestState} from "modules/enums";

export interface IHelp {
	title: string;
	text: string;
}

export interface IHelpState {
	guidelines: IHelp;
	game_rules: IHelp;
	prizes: IHelp;
	terms: IHelp;
	privacy_policy: IHelp;
}

export interface IJSONs {
	roundsRequestState: RequestState;
	rounds: IRound[];
	squads: ISquad[];
	ladder: ILadder[];
	countries: TCountry;
	roundStats: IDictionary<IRoundStat>;
	checksums: IDictionary<string>;
	help: IHelpState;
	faq: IHelp[];
}

const defaultHelpItem = {
	title: "",
	text: "",
};
const initial: IJSONs = {
	roundsRequestState: RequestState.IDLE,
	rounds: [],
	squads: [],
	ladder: [],
	countries: {},
	roundStats: {},
	checksums: {},
	help: {
		guidelines: {...defaultHelpItem},
		game_rules: {...defaultHelpItem},
		prizes: {...defaultHelpItem},
		terms: {...defaultHelpItem},
		privacy_policy: {...defaultHelpItem},
	},
	faq: [],
};

const onRequestLadderJsonSuccess = (state: IJSONs, ladder: ILadder[]) => ({
	...state,
	ladder,
});

export const jsons = createReducer({}, initial)
	.on(requestRoundsJSON, (state) => ({
		...state,
		roundsRequestState: RequestState.Requested,
	}))
	.on(requestRoundsJSONFailed, (state) => ({
		...state,
		roundsRequestState: RequestState.Received,
	}))
	.on(requestRoundsJSONSuccess, (state, payload) => ({
		...state,
		rounds: payload,
		roundsRequestState: RequestState.Received,
	}))
	.on(requestCountriesJSONSuccess, (state, payload) => ({
		...state,
		countries: payload,
	}))
	.on(requestSquadsJSONSuccess, (state, payload) => ({
		...state,
		squads: payload,
	}))
	.on(requestRoundStatsJSONSuccess, (state, payload) => ({
		...state,
		roundStats: payload,
	}))
	.on(fetchChecksumsJSONSuccess, (state, payload) => ({
		...state,
		checksums: payload,
	}))
	.on(requestLadderJsonSuccess, onRequestLadderJsonSuccess)
	.on(setHelpContent, (state, payload: IHelpState) => ({
		...state,
		help: {
			...payload,
		},
	}))
	.on(setFAQContent, (state, payload: IHelp[]) => ({
		...state,
		faq: payload,
	}));
