import styled from "styled-components";
import {BaseButton, buttonStyles} from "./index";

export const WarningButton = styled(BaseButton)`
	${({theme}) => buttonStyles(theme.button.warning)}
`;

export const WarningButtonOutlined = styled(BaseButton)`
	${({theme}) => buttonStyles(theme.button.warningOutlined)}
`;
