import {createReducer} from "redux-act";
import {ILeague} from "modules/reducers";
import {RequestState} from "modules/enums";
import {
	clearJoinLeagueState,
	clearLeaguesForJoin,
	getLeaguesForJoin,
	getLeaguesForJoinFailed,
	getLeaguesForJoinSuccess,
	IGetLeaguesForJoinSuccess,
	joinLeague,
	joinLeagueFailed,
	joinLeagueSuccess,
	joinLeagueWithRedirect,
	joinLeagueWithRedirectFailed,
	joinLeagueWithRedirectSuccess,
} from "modules/actions";

export interface IJoinLeague extends ILeague {
	isJoin?: boolean;
}

export interface ILeaguesForJoinReducer {
	leagues: IJoinLeague[];
	requestState: RequestState;
	joinLeagueRequestState: {
		[code: string]: RequestState;
	};
	nextPage: boolean;
	redirectId: string | null;
	isJoinWithRedirect: boolean;
	needRedirect: boolean;
}

const initialState: ILeaguesForJoinReducer = {
	leagues: [],
	requestState: RequestState.IDLE,
	joinLeagueRequestState: {},
	nextPage: true,
	redirectId: null,
	isJoinWithRedirect: false,
	needRedirect: false,
};

const onGetLeaguesForJoin = (state: ILeaguesForJoinReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

const onGetLeaguesForJoinSuccess = (
	state: ILeaguesForJoinReducer,
	payload: IGetLeaguesForJoinSuccess
) => ({
	...state,
	leagues: [...state.leagues, ...payload.leagues],
	nextPage: payload.nextPage,
	requestState: RequestState.Received,
});

const onGetLeaguesForJoinFailed = (state: ILeaguesForJoinReducer) => ({
	...state,
	requestState: RequestState.Received,
});

const onJoinLeague = (state: ILeaguesForJoinReducer, code: string) => ({
	...state,
	joinLeagueRequestState: {
		...state.joinLeagueRequestState,
		[code]: RequestState.Requested,
	},
});

const onJoinLeagueSuccess = (state: ILeaguesForJoinReducer, payload: ILeague) => ({
	...state,
	leagues: state.leagues.map((league) =>
		league.id === payload.id ? {...league, isJoin: true} : league
	),
	joinLeagueRequestState: {
		...state.joinLeagueRequestState,
		[payload.code]: RequestState.Received,
	},
});

const onJoinLeagueFailed = (state: ILeaguesForJoinReducer, code: string) => ({
	...state,
	joinLeagueRequestState: {
		...state.joinLeagueRequestState,
		[code]: RequestState.Received,
	},
});

const onClearLeaguesForJoin = (state: ILeaguesForJoinReducer) => ({
	...state,
	leagues: [],
	requestState: RequestState.IDLE,
});

const onJoinLeagueWithRedirect = (state: ILeaguesForJoinReducer) => ({
	...state,
	isJoinWithRedirect: true,
});

const onJoinLeagueWithRedirectSuccess = (state: ILeaguesForJoinReducer, redirectId: string) => ({
	...state,
	redirectId,
	needRedirect: true,
});

const onJoinLeagueWithRedirectFailed = (state: ILeaguesForJoinReducer) => ({
	...state,
	isJoinWithRedirect: false,
	needRedirect: true,
});

const onClearJoinLeagueState = () => ({
	...initialState,
});

export const leaguesForJoin = createReducer<ILeaguesForJoinReducer>({}, initialState)
	.on(getLeaguesForJoin, onGetLeaguesForJoin)
	.on(getLeaguesForJoinSuccess, onGetLeaguesForJoinSuccess)
	.on(getLeaguesForJoinFailed, onGetLeaguesForJoinFailed)
	.on(clearLeaguesForJoin, onClearLeaguesForJoin)
	.on(clearJoinLeagueState, onClearJoinLeagueState)
	.on(joinLeague, onJoinLeague)
	.on(joinLeagueSuccess, onJoinLeagueSuccess)
	.on(joinLeagueFailed, onJoinLeagueFailed)
	.on(joinLeagueWithRedirect, onJoinLeagueWithRedirect)
	.on(joinLeagueWithRedirectSuccess, onJoinLeagueWithRedirectSuccess)
	.on(joinLeagueWithRedirectFailed, onJoinLeagueWithRedirectFailed);
