import React from "react";
import styled from "styled-components";

const SpinnerStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	transition: all 0.2s linear;

	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 15px;
		height: 15px;
		margin: auto;
		border: 2px solid;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: ${({theme}) => theme.button.secondary.disabledColor} transparent transparent
			transparent;
	}

	div:nth-child(1) {
		animation-delay: -0.45s;
	}

	div:nth-child(2) {
		animation-delay: -0.3s;
	}

	div:nth-child(3) {
		animation-delay: -0.15s;
	}

	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
export const Spinner: React.FC = () => (
	<SpinnerStyled>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</SpinnerStyled>
);
