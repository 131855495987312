import {IStore} from "modules/types/store";
import {ILeaguesForJoinReducer} from "modules/reducers";
import {createSelector} from "reselect";
import {RequestState} from "modules/enums";

export const getLeaguesForJoinState = (state: IStore): ILeaguesForJoinReducer =>
	state.leaguesForJoin;

export const getJoinLeagueData = createSelector(
	getLeaguesForJoinState,
	(state: ILeaguesForJoinReducer) => {
		const isLeagues = !!state.leagues.length;
		const isLoading = state.requestState === RequestState.Requested && !isLeagues;
		const isShowNoFindLeagues = state.requestState !== RequestState.Requested && !isLeagues;

		return {
			...state,
			disabled: state.requestState === RequestState.Requested,
			isShowPreloader: isLoading,
			isShowNoFindLeagues,
			isLeagues,
		};
	}
);

export const getJoinLeagueRequestState = createSelector(
	getLeaguesForJoinState,
	({joinLeagueRequestState}) => joinLeagueRequestState
);

export const getIsJoiningLeague = createSelector(
	getLeaguesForJoinState,
	({isJoinWithRedirect, redirectId, needRedirect}) => ({
		isLoading: isJoinWithRedirect,
		redirectId,
		needRedirect,
	})
);
