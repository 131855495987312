import {
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	userLogin,
	userLoginFailed,
	userLoginSuccess,
	userLogoutFailed,
	userLogoutSuccess,
	userRegister,
	userRegisterFailed,
	userRegisterSuccess,
	showGlobalError,
	requestResetPassword,
	requestResetPasswordSuccess,
	requestResetPasswordFailed,
	resetPassword,
	resetPasswordSuccess,
	resetPasswordFailed,
	userUpdate,
	userUpdateSuccess,
	userUpdateFailed,
	userPreregister,
	userPreregisterSuccess,
	userPreregisterFailed,
	// reactivateCurrentUserSuccess,
	reactivateCurrentUserFailed,
	acceptTCFailed,
	userCheckTeamname,
	userCheckTeamnameFailed,
	userCheckTeamnameSuccess,
} from "modules/actions";
// import { IApiResponse } from "modules/types";
import {Api, ApiError} from "modules/utils";
import {call, put} from "typed-redux-saga";
import {hideAcceptTCModal, hideReactivateModal, showInfoModal} from "modules/actions/modals";

export const loginSaga = function* ({payload}: ReturnType<typeof userLogin>) {
	try {
		const response = yield* call(Api.Auth.login, payload);
		yield* put(userLoginSuccess(response.success.user));
	} catch (err) {
		yield* put(userLoginFailed(err as ApiError));
	}
};

export const registerSaga = function* ({payload}: ReturnType<typeof userRegister>) {
	try {
		const response = yield* call(Api.Auth.register, payload);
		const user = response.success.user;
		yield* put(userRegisterSuccess(user));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userRegisterFailed(err));
		}
	}
};

export const userCheckTeamnameSaga = function* ({payload}: ReturnType<typeof userCheckTeamname>) {
	try {
		yield* call(Api.User.checkTeamname, payload);
		yield* put(userCheckTeamnameSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userCheckTeamnameFailed(err));
		}
	}
};

export const preregisterSaga = function* ({payload}: ReturnType<typeof userPreregister>) {
	try {
		const response = yield* call(Api.Auth.preregister, payload);
		const {success} = response;
		if (success) {
			yield* put(userPreregisterSuccess());
		}
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userPreregisterFailed(err));
		}
	}
};

export const logoutSaga = function* () {
	try {
		yield* call(Api.Auth.logout);
		yield* put(userLogoutSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userLogoutFailed(err));
			yield* put(showGlobalError(err));
		}
	}
};

export const getCurrentUserSaga = function* () {
	try {
		const response = yield* call(Api.User.user);
		yield* put(requestCurrentUserSuccess(response.success.user));
	} catch (err) {
		yield* put(requestCurrentUserFailed());
	}
};

export const requestResetPasswordSaga = function* ({
	payload,
}: ReturnType<typeof requestResetPassword>) {
	try {
		yield* call(Api.Auth.requestPasswordReset, payload);
		yield* put(requestResetPasswordSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(requestResetPasswordFailed(err));
		}
	}
};

export const resetPasswordSaga = function* ({payload}: ReturnType<typeof resetPassword>) {
	try {
		yield* call(Api.Auth.passwordReset, payload);
		yield* put(resetPasswordSuccess());
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(resetPasswordFailed(err));
		}
	}
};

export const userUpdateSaga = function* ({payload}: ReturnType<typeof userUpdate>) {
	try {
		const response = yield* call(Api.User.update, payload);
		yield* put(userUpdateSuccess(response.success.user));

		if (payload.password) {
			yield* put(showInfoModal({message: "Your password has been changed"}));
		}
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(userUpdateFailed(err));
		}
	}
};

export const userReactivateSaga = function* ({payload}: ReturnType<typeof hideReactivateModal>) {
	try {
		yield* call(Api.User.reactivate, payload);
		// yield* put(reactivateCurrentUserSuccess(response.success.user));
	} catch (err) {
		yield* put(reactivateCurrentUserFailed());
	}
};

export const userAcceptTCSaga = function* ({payload}: ReturnType<typeof hideAcceptTCModal>) {
	try {
		yield* call(Api.User.acceptTC, payload);
	} catch (err) {
		yield* put(acceptTCFailed());
	}
};
