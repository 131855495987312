import {createReducer} from "redux-act";
import {
	hideAcceptTCModal,
	hideInfoModal,
	hideReactivateModal,
	hideSaveTipsModal,
	hideTnCModal,
	showAcceptTCModal,
	showInfoModal,
	showReactivateModal,
	showSaveTipsModal,
	showTnCModal,
} from "modules/actions/modals";
import {IModal} from "modules/types/modal";

interface IModalReducer {
	message: string;
	title: string;
	showSavedTipsModal: boolean;
	showTermsModal: boolean;
	showReactivateModal?: boolean;
	showAcceptTCModal?: boolean;
}

const initialState = {
	message: "",
	title: "",
	showSavedTipsModal: false,
	showTermsModal: false,
	showReactivateModal: false,
	showAcceptTCModal: false,
};

const onShowInfoModal = (state: IModalReducer, payload: IModal) => ({
	...state,
	title: payload.title || "",
	message: payload.message,
});

const onHideInfoModal = (state: IModalReducer) => ({
	...state,
	title: "",
	message: "",
});

const onShowSaveTipsModal = (state: IModalReducer) => ({
	...state,
	showSavedTipsModal: true,
});

const onHideSaveTipsModal = (state: IModalReducer) => ({
	...state,
	showSavedTipsModal: false,
});

const onShowTnCModal = (state: IModalReducer) => ({
	...state,
	showTermsModal: true,
});

const onHideTnCModal = (state: IModalReducer) => ({
	...state,
	showTermsModal: false,
});

const onShowReactivateModal = (state: IModalReducer) => ({
	...state,
	showReactivateModal: true,
});

const onHideReactivateModal = (state: IModalReducer) => ({
	...state,
	showReactivateModal: false,
});

const onShowAcceptTCModal = (state: IModalReducer) => ({
	...state,
	showAcceptTCModal: true,
});

const onHideAcceptTCModal = (state: IModalReducer) => ({
	...state,
	showAcceptTCModal: false,
});

export const modal = createReducer<IModalReducer>({}, initialState)
	.on(showInfoModal, onShowInfoModal)
	.on(hideInfoModal, onHideInfoModal)
	.on(showSaveTipsModal, onShowSaveTipsModal)
	.on(hideSaveTipsModal, onHideSaveTipsModal)
	.on(showTnCModal, onShowTnCModal)
	.on(hideTnCModal, onHideTnCModal)
	.on(showReactivateModal, onShowReactivateModal)
	.on(hideReactivateModal, onHideReactivateModal)
	.on(showAcceptTCModal, onShowAcceptTCModal)
	.on(hideAcceptTCModal, onHideAcceptTCModal);
