import React, {useCallback} from "react";
import {IJoinLeague} from "modules/reducers";
import DefaultTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import DefaultTableContainer, {TableContainerTypeMap} from "@mui/material/TableContainer";
import DefaultTableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import {OverridableComponent} from "@mui/types";
import {SecondaryButton} from "components";
import {useMediaQuery} from "modules/utils";
import {useDispatch, useSelector} from "react-redux";
import {getJoinLeagueRequestState} from "modules/selectors";
import {RequestState} from "modules";
import {Link} from "react-router-dom";
import {joinLeague} from "modules/actions";
import {Spinner} from "components/Preloader/Spinner";

export const TableContainer: OverridableComponent<TableContainerTypeMap> = styled(
	DefaultTableContainer
)`
	&& {
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	}
`;

export const Table = styled(DefaultTable)`
	width: 100%;
`;

const TableHead = styled(DefaultTableHead)`
	height: 80px;
`;

const StyledTableCell = styled(TableCell)`
	&.${tableCellClasses.head} {
		font-size: 12px;
		line-height: 14px;
		font-weight: 700;
		text-transform: uppercase;
		background: ${({theme}) => theme.table.head.primary};
		color: ${({theme}) => theme.table.head.primaryText};
		padding: 16px 10px;

		&:nth-child(1) {
			min-width: 160px;

			@media (max-width: 400px) {
				min-width: 110px;
			}

			@media (min-width: 769px) {
				padding-left: 24px;
			}
		}

		&:nth-child(2) {
			width: 100px;
		}

		&:nth-child(3) {
			width: 100px;
		}

		&:nth-child(4) {
			width: 140px;
		}

		@media (min-width: 769px) {
			padding: 16px;
		}
	}

	&.${tableCellClasses.body} {
		font-size: 12px;
		background: ${({theme}) => theme.table.body.primary};
		color: ${({theme}) => theme.table.body.primaryText};
	}
`;

export const StyledTableRow = styled(TableRow)`
	td:nth-child(3) {
		background: ${({theme}) => theme.table.body.secondary};
	}

	td {
		border-color: #f1f1f1;
	}

	&:last-child td,
	&:last-child th {
		border: 0;
	}
`;

const JoinButton = styled(SecondaryButton)`
	max-width: 100px;
	display: block;
	margin-left: auto;
`;

interface ILeaguesTable {
	leagues: IJoinLeague[];
}

export const LeaguesTable: React.FC<ILeaguesTable> = ({leagues}) => {
	const isMobile = useMediaQuery("(max-width: 768px)");

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<StyledTableCell>
							{isMobile ? "COMP NAME / OWNER" : "COMPETITION NAME / OWNER"}
						</StyledTableCell>
						<StyledTableCell align="center">
							{isMobile ? "START ROUND" : "STARTING ROUND"}
						</StyledTableCell>
						<StyledTableCell align="center">TIPPERS</StyledTableCell>
						<StyledTableCell align="center">JOIN</StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{leagues.map((league) => (
						<TableBodyItem key={league.id} {...league} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const TableBodyItem: React.FC<IJoinLeague> = (props) => {
	const dispatch = useDispatch();
	const joinLeagueRequestState = useSelector(getJoinLeagueRequestState);
	const {code, isJoin, id, name, startRound, numUsers} = props;
	const disabled = joinLeagueRequestState[code] === RequestState.Requested;

	const selectHandler = useCallback(() => {
		dispatch(joinLeague(code));
	}, [code, dispatch]);

	return (
		<StyledTableRow key={id}>
			<StyledTableCell style={{paddingLeft: 24}}>{name}</StyledTableCell>
			<StyledTableCell align="center">{startRound}</StyledTableCell>
			<StyledTableCell align="center">{numUsers}</StyledTableCell>
			<StyledTableCell align="center">
				{isJoin ? (
					<Link to={`/league/${id}/standings`}>
						<JoinButton>View</JoinButton>
					</Link>
				) : (
					<JoinButton onClick={selectHandler} disabled={disabled}>
						{disabled ? <Spinner /> : "Join"}
					</JoinButton>
				)}
			</StyledTableCell>
		</StyledTableRow>
	);
};
