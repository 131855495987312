import React from "react";
import alertIcon from "assets/images/icons/alert.png";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {
	ButtonsBox,
	CloseButton,
	Img,
	Modal,
	ModalContainer,
	ModalContent,
	Text,
	Title,
} from "components/Modals/common";
import {SecondaryButton, WarningButton} from "components/Buttons";

interface IClearTipsModal {
	open: boolean;
	closeHandler: () => void;
	submitHandler: () => void;
}

export const ClearTipsModal: React.FC<IClearTipsModal> = ({closeHandler, open, submitHandler}) => {
	if (!open) {
		return null;
	}

	return (
		<ModalContainer>
			<Modal>
				<CloseButton onClick={closeHandler} id="btn-clear-tips-modal">
					<CloseIcon />
				</CloseButton>
				<ModalContent>
					<Img src={alertIcon} alt="" />
					<Title>Clear all</Title>
					<Text>Are you sure you want to clear all picks made?</Text>
					<ButtonsBox>
						<SecondaryButton onClick={closeHandler}>No</SecondaryButton>
						<WarningButton onClick={submitHandler}>Yes</WarningButton>
					</ButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
