import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";
import {get} from "lodash";

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const rootPrivateURL = "/predictor";
	let backURL = get(location, "state.from.pathname", rootPrivateURL) as string;

	if (["/logout"].includes(backURL)) {
		backURL = rootPrivateURL;
	}

	if (useSelector(isLoggedIn)) {
		return <Navigate to={backURL} replace />;
	}
	return <Outlet />;
};
