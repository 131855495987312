import styled from "styled-components";
import React from "react";
import {TeamRankings} from "components/TeamRankings";
import {ExternalLink} from "components/Common";
import {useSelector} from "react-redux";
import {getUserAds} from "modules/selectors";
import {APP_URL} from "modules";
import {useMediaQuery} from "modules/utils";

export const AsideWrapper = styled.div`
	padding: 0 20px;
	&.less-padding-mobile {
		padding: 0 10px;
	}

	@media (min-width: 769px) {
		padding: 0;
	}

	@media (min-width: 1025px) {
		max-width: 300px;
		width: 100%;
		margin-left: 40px;
	}
`;

export const AsideContainer = styled.aside`
	width: 100%;
	margin-bottom: 30px;
`;

export const MREC = styled.div<{img?: string}>`
	width: 300px;
	height: 250px;
	margin: 0 auto 20px;
	background-image: url(${(props) => props.img});
`;

export const Aside: React.FC = () => {
	const geolocation = useSelector(getUserAds);
	const isMobile = useMediaQuery(`(max-width: 769px`);
	const imgUrl = isMobile ? geolocation.ads.mrec.mobileImage : geolocation.ads.mrec.desktopImage;
	const img = APP_URL + imgUrl;

	return (
		<AsideContainer>
			<ExternalLink href={geolocation.ads.mrec.link}>
				<MREC img={img} />
			</ExternalLink>
			<TeamRankings />
		</AsideContainer>
	);
};
