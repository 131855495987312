import {IStore} from "modules/types/store";
import {ILeagueReducer} from "modules/reducers";
import {createSelector} from "reselect";
import {RequestState} from "modules/enums";

export const getLeagueState = (state: IStore): ILeagueReducer => state.league;
export const getCurrentLeague = createSelector(getLeagueState, ({league}) => league);
export const getLeagueRankingsList = createSelector(getLeagueState, ({rankings}) => rankings);
export const getUsers = createSelector(getLeagueState, ({users}) => users);
export const getLeagueManageStateData = createSelector(getLeagueState, (state) => {
	const isLoading = state.getUsersRequestState === RequestState.Requested;
	const isUsers = !!state.users.length;

	return {
		...state,
		nextPage: state.usersNextPage,
		requestState: state.getUsersRequestState,
		isLoading,
		showInitialLoader: isLoading && !isUsers,
		showAltText: !isLoading && !isUsers,
		isUsers,
		showLoadMore: isUsers && state.usersNextPage,
	};
});
export const getUsersRequestState = createSelector(
	getLeagueState,
	({getUsersRequestState}) => getUsersRequestState
);
