import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearLeagueState, getLeague} from "modules/actions";
import {getLeagueState} from "modules/selectors";
import {RequestState} from "modules";
import {Preloader} from "components/Preloader";
import {useScrollToTop} from "modules/utils/hooks/useScrollToTop";

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
// because hooks are used inside FC which return this HOC
export const HOCLeagueLayout = (Children: React.FC) => () => {
	useScrollToTop();
	const [isLoad, setIsLoad] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {leagueId} = useParams();
	const {league, requestState, needRedirect} = useSelector(getLeagueState);
	const isLoading = requestState === RequestState.Requested || isLoad;

	useEffect(() => {
		dispatch(clearLeagueState());
		dispatch(getLeague(leagueId!));
		setIsLoad(false);

		return () => {
			dispatch(clearLeagueState());
		};
	}, [leagueId, dispatch]);

	useEffect(() => {
		if (needRedirect) {
			navigate("/leagues");
		}
	}, [dispatch, navigate, needRedirect]);

	if (isLoading || !league) {
		return <Preloader />;
	}

	return <Children />;
};
