import styled from "styled-components";

export const Container = styled.div`
	max-width: 1200px;
	width: 100%;
	padding: 0 10px;
	margin: 0 auto;
	box-sizing: border-box;
`;

export const ExternalLink = styled.a``;

ExternalLink.defaultProps = {
	target: "_blank",
	rel: "noreferrer noopener",
};

export const Flexible = styled.div`
	display: flex;
	align-items: center;
`;
