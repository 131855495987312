import styled from "styled-components";

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	flex-direction: column;

	@media (min-width: 1025px) {
		flex-direction: row;
	}
`;
