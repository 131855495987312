import {createReducer} from "redux-act";
import {IRanking} from "modules/reducers/league";
import {RequestState} from "modules/enums";
import {
	clearRankings,
	getRankings,
	getRankingsFailed,
	getRankingsSuccess,
	IGetRankingsSuccess,
} from "modules/actions";

export interface IRankingsReducer {
	rankings: IRanking[];
	requestState: RequestState;
	nextPage: boolean;
}

const initialState: IRankingsReducer = {
	rankings: [],
	requestState: RequestState.IDLE,
	nextPage: false,
};

const onGetRankings = (state: IRankingsReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

const onGetRankingsSuccess = (state: IRankingsReducer, payload: IGetRankingsSuccess) => ({
	...state,
	rankings: [...state.rankings, ...payload.rankings],
	nextPage: payload.nextPage,
	requestState: RequestState.Received,
});

const onGetRankingsFailed = (state: IRankingsReducer) => ({
	...state,
	requestState: RequestState.Received,
});

const onClearRankings = (state: IRankingsReducer) => ({
	...state,
	rankings: [],
});

export const rankings = createReducer<IRankingsReducer>({}, initialState)
	.on(getRankings, onGetRankings)
	.on(getRankingsSuccess, onGetRankingsSuccess)
	.on(getRankingsFailed, onGetRankingsFailed)
	.on(clearRankings, onClearRankings);
