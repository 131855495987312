import {createReducer} from "redux-act";
import {
	clearResetPasswordState,
	markPasswordAsUpdated,
	markUserInfoAsUpdated,
	requestCurrentUser,
	requestCurrentUserFailed,
	requestCurrentUserSuccess,
	requestResetPassword,
	requestResetPasswordFailed,
	requestResetPasswordSuccess,
	resetPassword,
	resetPasswordFailed,
	resetPasswordSuccess,
	unMarkPasswordAsUpdated,
	unMarkUserInfoAsUpdated,
	userClearError,
	userLogin,
	userLoginFailed,
	userLoginSuccess,
	userLogout,
	userLogoutFailed,
	userLogoutSuccess,
	userRegister,
	userRegisterFailed,
	userRegisterSuccess,
	userUpdate,
	userUpdateFailed,
	userUpdateSuccess,
	userPreregister,
	userPreregisterSuccess,
	userPreregisterFailed,
	// reactivateCurrentUserSuccess,
	reactivateCurrentUserFailed,
	acceptTCFailed,
	userCheckTeamname,
	userCheckTeamnameFailed,
	userCheckTeamnameSuccess,
} from "modules/actions/user";
import {RequestState} from "modules/enums";
import {ApiError} from "modules/utils";

export interface IUser {
	id: number;
	firstName: string;
	lastName: string;
	username: string;
	email: string;
	disabledNotifications?: boolean;
	isBrandOptIn?: boolean;
	isDirectMarketingOptIn?: boolean;
	createdAt?: string;
	registeredAt?: null | "NOW";
	isActive?: boolean;
	termsAccepted?: null | string;
}

export interface IUserReducer {
	user?: IUser;
	error?: string;
	teamNameError?: string;
	requestState: RequestState;
	sessionCheckedState: RequestState;
	logoutWasCalled: boolean;
	passwordUpdated: boolean;
	userInfoUpdated: boolean;
	passwordReset: {
		requestState: RequestState;
		error?: string;
	};
	preregisterSuccess: boolean;
}

const defaultState: IUserReducer = {
	requestState: RequestState.IDLE,
	sessionCheckedState: RequestState.IDLE,
	logoutWasCalled: false,
	passwordUpdated: false,
	userInfoUpdated: false,
	passwordReset: {
		requestState: RequestState.IDLE,
	},
	preregisterSuccess: false,
};

const onError = (state: IUserReducer, error: ApiError) => ({
	...state,
	error: error.message,
	requestState: RequestState.Received,
});

const onTeamnameError = (state: IUserReducer, error: ApiError) => {
	return {
		...state,
		teamNameError: error.message,
		requestState: RequestState.Received,
	};
};

const onAuthRequest = (state: IUserReducer) => ({
	...state,
	requestState: RequestState.Requested,
	logoutWasCalled: false,
});

const onReceiveUser = (state: IUserReducer, user: IUser) => ({
	...state,
	user,
	error: undefined,
	requestState: RequestState.Received,
});

const onUserCheckTeamnameSuccess = (state: IUserReducer) => ({
	...state,
	onTeamnameError: undefined,
	requestState: RequestState.Received,
});

const onResetPasswordFailed = (state: IUserReducer, error: ApiError) => ({
	...state,
	passwordReset: {
		requestState: RequestState.Received,
		error: error.message,
	},
});

const onResetPasswordSuccess = (state: IUserReducer) => ({
	...state,
	passwordReset: {
		requestState: RequestState.Received,
	},
});

const onResetPasswordRequested = (state: IUserReducer) => ({
	...state,
	passwordReset: {
		requestState: RequestState.Requested,
	},
});

export const user = createReducer<IUserReducer>({}, defaultState)
	/**
	 * Login
	 */
	.on(userLogin, onAuthRequest)
	.on(userLoginSuccess, (state, user) => ({
		...state,
		user,
		isAuthorized: true,
		error: undefined,
		requestState: RequestState.Received,
	}))
	.on(userLoginFailed, onError)
	/**
	 * Update
	 */
	.on(userUpdate, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(userUpdateSuccess, onReceiveUser)
	.on(userUpdateFailed, onError)
	.on(markUserInfoAsUpdated, (state) => ({
		...state,
		userInfoUpdated: true,
	}))
	.on(markPasswordAsUpdated, (state) => ({
		...state,
		passwordUpdated: true,
	}))
	.on(unMarkUserInfoAsUpdated, (state) => ({
		...state,
		userInfoUpdated: false,
	}))
	.on(unMarkPasswordAsUpdated, (state) => ({
		...state,
		passwordUpdated: false,
	}))
	/**
	 * Logout
	 */
	.on(userLogout, (state) => ({
		...state,
		requestState: RequestState.Requested,
		logoutWasCalled: true,
	}))
	.on(userLogoutSuccess, (state) => ({
		...state,
		user: undefined,
		requestState: RequestState.Received,
	}))
	.on(userLogoutFailed, onError)
	/**
	 * Register
	 */
	.on(userRegister, onAuthRequest)
	.on(userRegisterSuccess, onReceiveUser)
	.on(userRegisterFailed, onError)
	.on(userCheckTeamname, (state) => ({
		...state,
		teamNameError: undefined,
	}))
	.on(userCheckTeamnameSuccess, onUserCheckTeamnameSuccess)
	.on(userCheckTeamnameFailed, onTeamnameError)
	.on(userPreregister, onAuthRequest)
	.on(userPreregisterSuccess, (state) => ({
		...state,
		preregisterSuccess: true,
	}))
	.on(userPreregisterFailed, onError)
	/**
	 * Get current user
	 */
	.on(requestCurrentUser, (state) => ({
		...state,
		sessionCheckedState: RequestState.Requested,
		logoutWasCalled: false,
	}))
	.on(requestCurrentUserSuccess, (state, user) => ({
		...state,
		user,
		error: undefined,
		sessionCheckedState: RequestState.Received,
	}))
	.on(requestCurrentUserFailed, (state) => ({
		...state,
		sessionCheckedState: RequestState.Received,
	}))

	// .on(reactivateCurrentUserSuccess, (state, user) => ({
	// 	...state,
	// 	user,
	// 	error: undefined,
	// 	sessionCheckedState: RequestState.Received,
	// }))
	.on(reactivateCurrentUserFailed, (state) => ({
		...state,
		sessionCheckedState: RequestState.Received,
	}))
	.on(acceptTCFailed, (state) => ({
		...state,
		sessionCheckedState: RequestState.Received,
	}))
	/**
	 * Password reset
	 */
	.on(requestResetPassword, onResetPasswordRequested)
	.on(resetPassword, onResetPasswordRequested)
	.on(requestResetPasswordSuccess, onResetPasswordSuccess)
	.on(resetPasswordSuccess, onResetPasswordSuccess)
	.on(requestResetPasswordFailed, onResetPasswordFailed)
	.on(resetPasswordFailed, onResetPasswordFailed)
	.on(clearResetPasswordState, (state, error) => ({
		...state,
		passwordReset: defaultState.passwordReset,
	}))
	/**
	 * Common
	 */
	.on(userClearError, (state) => ({
		...state,
		error: undefined,
	}));
