export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const APP_URL = process.env.REACT_APP_LINK || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";

export const STORAGE_VERSION = 1;
export const EMAIL_REGEXP = /\S+@\S+\.\S+/;
export const USER_DOB_FORMAT = "dd/MM/yyyy";
export const USER_DOB_FORMAT_PAYLOAD = "yyyy-MM-dd";
