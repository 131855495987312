import {
	requestRoundsJSONSuccess,
	requestRoundsJSONFailed,
	requestSquadsJSONFailed,
	requestSquadsJSONSuccess,
	requestCountriesJSONSuccess,
	requestCountriesJSONFailed,
	requestRoundStatsJSONSuccess,
	requestRoundStatsJSONFailed,
	fetchChecksumsJSONSuccess,
	fetchChecksumsJSONFailed,
	setHelpContent,
	showGlobalError,
	requestLadderJsonSuccess,
	requestLadderJsonFailed,
	setFAQContent,
} from "modules/actions";
import {call, put} from "typed-redux-saga";
import {Api, ApiError} from "modules/utils";
import {ISagaAction} from "modules/types";
import {keyBy} from "lodash";
import {SagaIterator} from "redux-saga";

export const fetchChecksumsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.checksums);
		yield* put(fetchChecksumsJSONSuccess(result));
	} catch (err) {
		yield* put(fetchChecksumsJSONFailed());
	}
};

export const requestRoundsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.rounds);
		yield* put(requestRoundsJSONSuccess(result));
	} catch (err) {
		yield* put(requestRoundsJSONFailed());
	}
};

export const requestCountriesJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.countries);
		yield* put(requestCountriesJSONSuccess(result));
	} catch (err) {
		yield* put(requestCountriesJSONFailed());
	}
};

export const requestSquadsJSONSaga = function* () {
	try {
		const result = yield* call(Api.JSON.squads);
		yield* put(requestSquadsJSONSuccess(result));
	} catch (err) {
		yield* put(requestSquadsJSONFailed());
	}
};

export const requestRoundStatsJSONSaga = function* ({payload}: ISagaAction<number>) {
	try {
		const result = yield* call(Api.JSON.round_stats, payload);
		yield* put(requestRoundStatsJSONSuccess(keyBy(result, "tournamentId")));
	} catch (err) {
		yield* put(requestRoundStatsJSONFailed());
	}
};

export const fetchFaqSaga = function* (): SagaIterator {
	try {
		const result = yield* call(Api.JSON.faqs);

		yield* put(setFAQContent(result));
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const fetchHelpContentSaga = function* (): SagaIterator {
	try {
		const helpPages = yield* call(Api.JSON.help_pages);

		yield* put(
			setHelpContent({
				...helpPages,
			})
		);
	} catch (err) {
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const requestLadderJsonSaga = function* (): SagaIterator {
	try {
		const helpPages = yield* call(Api.JSON.ladder);

		yield* put(requestLadderJsonSuccess(helpPages));
	} catch (e) {
		yield* put(requestLadderJsonFailed());
	}
};
