import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {ILeaguesReducer} from "modules/reducers";

export const getLeaguesState = (state: IStore): ILeaguesReducer => state.leagues;
export const getCreateLeague = createSelector(getLeaguesState, ({createLeague}) => createLeague);
export const getButtonCreateLeagueState = createSelector(
	getLeaguesState,
	({createLeague}) => createLeague.buttonState
);
