import {createAction} from "redux-act";
import {TournamentStatusType, RoundStatusType} from "modules/enums";
import {IDictionary} from "modules/types";
import {IHelp, IHelpState} from "modules/reducers";

export interface IRound {
	id: number;
	status: RoundStatusType;
	startDate: string;
	endDate: string;
	tournaments: ITournament[];
}

export interface ITournament {
	id: number;
	number: number;
	venueName: string;
	venueId: number;
	date: string;
	isMarginGame: boolean;
	homeSquadId: number;
	awaySquadId: number;
	homeSquadName: string;
	awaySquadName: string;
	homeSquadShortName: string;
	awaySquadShortName: string;
	homeScore: null;
	awayScore: null;
	status: TournamentStatusType;
}

export type TCountry = {
	[code: string]: string;
};

export interface ISquad {
	id: number;
	name: string;
	shortName: string;
	customLogo: null;
	fullName: string;
	color: string;
}

export interface IRoundStat {
	tournamentId: number;
	homeSquadId: number;
	awaySquadId: number;
	awayPercent: number;
	homePercent: number;
}

export interface ILadder {
	id: number;
	name: string;
	games: number;
	win: number;
	loss: number;
	points: number;
	for: number;
	against: number;
	difference: number;
}

export const requestRoundsJSON = createAction();
export const requestRoundsJSONSuccess = createAction<IRound[]>();
export const requestRoundsJSONFailed = createAction();

export const requestSquadsJSON = createAction();
export const requestSquadsJSONSuccess = createAction<ISquad[]>();
export const requestSquadsJSONFailed = createAction();

export const requestCountriesJSON = createAction();
export const requestCountriesJSONSuccess = createAction<TCountry>();
export const requestCountriesJSONFailed = createAction();

export const requestRoundStatsJSON = createAction<number>();
export const requestRoundStatsJSONSuccess = createAction<IDictionary<IRoundStat>>();
export const requestRoundStatsJSONFailed = createAction();

export const requestLadderJson = createAction();
export const requestLadderJsonSuccess = createAction<ILadder[]>();
export const requestLadderJsonFailed = createAction();

export const fetchChecksumsJSON = createAction();
export const fetchChecksumsJSONSuccess = createAction<IDictionary<string>>();
export const fetchChecksumsJSONFailed = createAction();

export const getHelpContent = createAction();
export const getFaqContent = createAction();
export const setHelpContent = createAction<IHelpState>();
export const setFAQContent = createAction<IHelp[]>();
