import {createAction} from "redux-act";
import {ILeague, ILeagueUser, IRanking} from "modules/reducers";
import {IInviteUsersToLeague, IUpdateLeaguesPayload} from "modules/actions";

export interface IGetLeagueRankingsPayload {
	id: string;
	search?: string;
	round?: number;
	page?: number;
	limit?: number;
}

export interface IGetLeagueUsersPayload {
	id: string;
	search?: string;
	page?: number;
	limit?: number;
}

export interface IGetUsersSuccess {
	users: ILeagueUser[];
	nextPage: boolean;
}

export interface IRemoveLeagueUserPayload {
	id: string;
	userId: string;
}

export interface IGetLeague {
	isJoined: boolean;
	league: ILeague;
}

export interface IGetLeagueRankingsSuccess {
	rankings: IRanking[];
	nextPage: boolean;
}

export const getLeague = createAction<string>();
export const getLeagueSuccess = createAction<IGetLeague>();
export const getLeagueFailed = createAction();

export const getLeagueRankings = createAction<IGetLeagueRankingsPayload>();
export const getLeagueRankingsSuccess = createAction<IGetLeagueRankingsSuccess>();
export const getLeagueRankingsFailed = createAction();

export const getLeagueUsers = createAction<IGetLeagueUsersPayload>();
export const getLeagueUsersSuccess = createAction<IGetUsersSuccess>();
export const getLeagueUsersFailed = createAction();

export const updateLeague = createAction<IUpdateLeaguesPayload>();
export const updateLeagueSuccess = createAction<ILeague>();
export const updateLeagueFailed = createAction();

export const inviteUsersToLeague = createAction<IInviteUsersToLeague>();
export const inviteUsersToLeagueSuccess = createAction();
export const inviteUsersToLeagueFailed = createAction();

export const leaveLeague = createAction<string>();
export const leaveLeagueSuccess = createAction();
export const leaveLeagueFailed = createAction();

export const deleteLeague = createAction<string>();
export const deleteLeagueSuccess = createAction();
export const deleteLeagueFailed = createAction();

export const deleteLeagueUser = createAction<IRemoveLeagueUserPayload>();
export const deleteLeagueUserSuccess = createAction<number>();
export const deleteLeagueUserFailed = createAction<string>();

export const clearLeagueState = createAction();
export const clearLeagueRankings = createAction();
