import {ButtonType, CreateLeaguesTab, PrivacyType, RequestState} from "modules";
import {createReducer} from "redux-act";
import {
	clearCreateLeagueState,
	createLeague,
	createLeagueFailed,
	createLeagueSuccess,
	getLeagues,
	getLeaguesFailed,
	getLeaguesSuccess,
	IGetLeaguesSuccess,
	inviteUsersToCreateLeague,
	inviteUsersToCreateLeagueFailed,
	inviteUsersToCreateLeagueSuccess,
	setCreateLeagueButtonText,
	setEditMode,
	createdUpdateLeague,
	createdUpdateLeagueFailed,
	createdUpdateLeagueSuccess,
} from "modules/actions";

export interface ILeague {
	id: number;
	name: string;
	startRound: number;
	userId: number;
	code: string;
	privacy: PrivacyType;
	status: string;
	class: string;
	numUsers: number;
}

export interface IUserLeague {
	id: number;
	userId: number | null;
	name: string;
	status: string;
	class: string;
	startRound: number;
	privacy: string;
	code: string;
	numUsers: number;
	overallRank: number | null;
	roundPoints: number | null;
	overallPoints: number | null;
}

export interface ICreateLeague {
	league: ILeague | null;
	activeTab: CreateLeaguesTab;
	disableSecondTab: boolean;
	requestState: RequestState;
	inviteRequestState: RequestState;
	needRedirect: boolean;
	isEditMode: boolean;
	buttonState: ButtonType;
	inviteButtonState: ButtonType;
}

export interface ILeaguesReducer {
	createLeague: ICreateLeague;
	leagues: IUserLeague[];
	requestState: RequestState;
	nextPage: boolean;
}

const initialState: ILeaguesReducer = {
	createLeague: {
		league: null,
		activeTab: CreateLeaguesTab.FIRST,
		disableSecondTab: true,
		requestState: RequestState.IDLE,
		inviteRequestState: RequestState.IDLE,
		needRedirect: false,
		isEditMode: false,
		buttonState: ButtonType.Disabled,
		inviteButtonState: ButtonType.Disabled,
	},
	leagues: [],
	requestState: RequestState.IDLE,
	nextPage: false,
};

const onSetCreateLeagueButtonText = (state: ILeaguesReducer, buttonState: ButtonType) => ({
	...state,
	createLeague: {
		...state.createLeague,
		buttonState,
	},
});

const onCreateLeagueSuccess = (state: ILeaguesReducer, league: ILeague) => ({
	...state,
	createLeague: {
		...state.createLeague,
		league: league,
		activeTab: CreateLeaguesTab.SECOND,
		disableSecondTab: false,
		requestState: RequestState.Received,
		buttonState: ButtonType.Enabled,
	},
});

const onCreateLeagueFailed = (state: ILeaguesReducer) => ({
	...state,
	createLeague: {
		...state.createLeague,
		requestState: RequestState.Received,
		buttonState: ButtonType.Enabled,
	},
});

const onCreateLeague = (state: ILeaguesReducer) => ({
	...state,
	createLeague: {
		...state.createLeague,
		requestState: RequestState.Requested,
		buttonState: ButtonType.Saving,
	},
});

const onInviteUsersToLeague = (state: ILeaguesReducer) => ({
	...state,
	createLeague: {
		...state.createLeague,
		inviteRequestState: RequestState.Requested,
		inviteButtonState: ButtonType.Saving,
	},
});

const onInviteUsersToLeagueSuccess = (state: ILeaguesReducer) => ({
	...state,
	createLeague: {
		...state.createLeague,
		inviteRequestState: RequestState.Received,
		needRedirect: true,
		inviteButtonState: ButtonType.Disabled,
	},
});

const onInviteUsersToLeagueFailed = (state: ILeaguesReducer) => ({
	...state,
	createLeague: {
		...state.createLeague,
		inviteRequestState: RequestState.Received,
		inviteButtonState: ButtonType.Enabled,
	},
});

const onClearCreateLeagueState = (state: ILeaguesReducer) => ({
	...state,
	createLeague: {
		league: null,
		activeTab: CreateLeaguesTab.FIRST,
		disableSecondTab: true,
		requestState: RequestState.IDLE,
		inviteRequestState: RequestState.IDLE,
		needRedirect: false,
		isEditMode: false,
		buttonState: ButtonType.Disabled,
		inviteButtonState: ButtonType.Disabled,
	},
});

const onSetEditMode = (state: ILeaguesReducer, isEditMode: boolean) => ({
	...state,
	createLeague: {
		...state.createLeague,
		isEditMode,
	},
});

const onGetLeagues = (state: ILeaguesReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

const onGetLeaguesSuccess = (state: ILeaguesReducer, payload: IGetLeaguesSuccess) => ({
	...state,
	requestState: RequestState.Received,
	leagues: [...state.leagues, ...payload.leagues],
	nextPage: payload.nextPage,
});

const onGetLeaguesFailed = (state: ILeaguesReducer) => ({
	...state,
	requestState: RequestState.Received,
});

export const leagues = createReducer<ILeaguesReducer>({}, initialState)
	/**
	 * Gets League
	 * */
	.on(getLeagues, onGetLeagues)
	.on(getLeaguesSuccess, onGetLeaguesSuccess)
	.on(getLeaguesFailed, onGetLeaguesFailed)
	/**
	 * Create League
	 * */
	.on(createLeague, onCreateLeague)
	.on(setCreateLeagueButtonText, onSetCreateLeagueButtonText)
	.on(createLeagueSuccess, onCreateLeagueSuccess)
	.on(createLeagueFailed, onCreateLeagueFailed)
	/**
	 * Update League
	 * */
	.on(createdUpdateLeague, onCreateLeague)
	.on(createdUpdateLeagueSuccess, onCreateLeagueSuccess)
	.on(createdUpdateLeagueFailed, onCreateLeagueFailed)
	/**
	 * Invite Users To League
	 * */
	.on(inviteUsersToCreateLeague, onInviteUsersToLeague)
	.on(inviteUsersToCreateLeagueSuccess, onInviteUsersToLeagueSuccess)
	.on(inviteUsersToCreateLeagueFailed, onInviteUsersToLeagueFailed)

	.on(clearCreateLeagueState, onClearCreateLeagueState)
	.on(setEditMode, onSetEditMode);
