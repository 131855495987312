import React, {Fragment} from "react";
import styled from "styled-components";
import {Link as DefaultLink} from "react-router-dom";
import {Collapse} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const LinkItem = styled.div`
	border-bottom: 1px solid ${({theme}) => theme.menuDrawer.secondary};
	cursor: pointer;
`;

const Links = styled.div`
	display: flex;
	flex-direction: column;
`;

const Link = styled(DefaultLink)`
	width: 100%;
	padding: 20px 25px 20px 20px;
	border-bottom: 1px solid ${({theme}) => theme.menuDrawer.secondary};
	font-weight: 700;
`;

const CollapseMenuLink = styled(Link)`
	width: auto;
	border-bottom: none;
`;

const ChildLink = styled(DefaultLink)`
	padding: 12px 25px 20px 20px;
`;

const CollapseLinkContainer = styled.div`
	padding-right: 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;
`;

const CollapseList = styled.div`
	display: flex;
	flex-direction: column;
`;

interface ILink {
	name: string;
	url: string;
}

interface IParentLink {
	name: string;
	url: string;
	urls?: ILink[];
	target?: "_blank";
}

export const links: IParentLink[] = [
	{url: "/predictor", name: "Tips"},
	{
		name: "Your Leagues",
		url: "/leagues",
		urls: [
			{url: "/leagues/join", name: "Join a League"},
			{url: "/leagues/create", name: "Create a League"},
		],
	},
	{url: "/rankings", name: "Rankings"},
	{url: "/help", name: "Help"},
	{url: "/#", name: "Super Rugby Pacific", target: "_blank"},
	{url: "/account", name: "Account"},
];

export const noAuthLinks: IParentLink[] = [
	{url: "/login", name: "Log in"},
	{url: "/help", name: "Help"},
];

interface ICollapseLink {
	name: string;
	url: string;
	urls: ILink[];
	closeHandler: () => void;
}

const CollapseLink: React.FC<ICollapseLink> = ({url, urls, name, closeHandler}) => {
	const [open, setOpen] = React.useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<LinkItem onClick={handleClick}>
			<CollapseLinkContainer>
				<CollapseMenuLink to={url} onClick={closeHandler}>
					{name}
				</CollapseMenuLink>
				{open ? <ExpandLess /> : <ExpandMore />}
			</CollapseLinkContainer>
			<Collapse in={open} timeout="auto" unmountOnExit>
				<CollapseList>
					{urls.map(({url, name}) => (
						<ChildLink to={url} key={name} onClick={closeHandler}>
							{name}
						</ChildLink>
					))}
				</CollapseList>
			</Collapse>
		</LinkItem>
	);
};

interface INavList {
	closeHandler: () => void;
	links: IParentLink[];
}

export const NavList: React.FC<INavList> = ({closeHandler, links}) => {
	return (
		<Links>
			{links.map(({url, name, urls}) => (
				<Fragment key={name}>
					{urls ? (
						<CollapseLink
							name={name}
							url={url}
							urls={urls}
							closeHandler={closeHandler}
						/>
					) : (
						<Link to={url} onClick={closeHandler}>
							{name}
						</Link>
					)}
				</Fragment>
			))}
		</Links>
	);
};
