import {createReducer} from "redux-act";
import {RequestState} from "modules/enums";
import {
	clearContact,
	postContact,
	postContactFailed,
	postContactSuccess,
} from "modules/actions/contact";

export interface IContact {
	requestState: RequestState;
}

const initial = {
	requestState: RequestState.IDLE,
};

export const contact = createReducer({}, initial)
	.on(postContact, (state) => ({
		...state,
		requestState: RequestState.Requested,
	}))
	.on(postContactSuccess, (state) => ({
		...state,
		requestState: RequestState.Received,
	}))
	.on(postContactFailed, (state) => ({
		...state,
		requestState: RequestState.IDLE,
	}))
	.on(clearContact, (state) => ({
		...state,
		requestState: RequestState.IDLE,
	}));
