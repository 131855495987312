import {createAction} from "redux-act";
import {ButtonType, PrivacyType} from "modules/enums";
import {ILeague, IUserLeague} from "modules/reducers";
import {IGetLeagues, IGetLeaguesForJoin} from "modules/utils";

export interface IGetLeaguesSuccess {
	leagues: IUserLeague[];
	nextPage: boolean;
}

export interface ICreateLeaguesPayload {
	name: string;
	startRound: number;
	privacy: PrivacyType;
}

export interface IInviteUser {
	name: string;
	email: string;
}

export interface IInviteUsersToLeague {
	leagueId: number;
	users: IInviteUser[];
}

export interface IUpdateLeaguesPayload extends ICreateLeaguesPayload {
	id: number;
}

export interface IGetLeaguesForJoinSuccess {
	leagues: ILeague[];
	nextPage: boolean;
}

export const getLeagues = createAction<IGetLeagues>();
export const getLeaguesSuccess = createAction<IGetLeaguesSuccess>();
export const getLeaguesFailed = createAction();

export const getLeaguesForJoin = createAction<IGetLeaguesForJoin>();
export const getLeaguesForJoinSuccess = createAction<IGetLeaguesForJoinSuccess>();
export const getLeaguesForJoinFailed = createAction();
export const clearLeaguesForJoin = createAction();

export const createLeague = createAction<ICreateLeaguesPayload>();
export const setCreateLeagueButtonText = createAction<ButtonType>();
export const createLeagueSuccess = createAction<ILeague>();
export const createLeagueFailed = createAction();

export const joinLeague = createAction<string>();
export const joinLeagueSuccess = createAction<ILeague>();
export const joinLeagueFailed = createAction<string>();

export const joinLeagueWithRedirect = createAction<string>();
export const joinLeagueWithRedirectSuccess = createAction<string>();
export const joinLeagueWithRedirectFailed = createAction();

export const clearCreateLeagueState = createAction();
export const clearJoinLeagueState = createAction();
export const createdUpdateLeague = createAction<IUpdateLeaguesPayload>();
export const createdUpdateLeagueSuccess = createAction<ILeague>();
export const createdUpdateLeagueFailed = createAction();
export const setEditMode = createAction<boolean>();

export const inviteUsersToCreateLeague = createAction<IInviteUsersToLeague>();
export const inviteUsersToCreateLeagueSuccess = createAction();
export const inviteUsersToCreateLeagueFailed = createAction();
