import React, {Fragment, useCallback, useState} from "react";
import {useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";
import {IconButton} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import {NavLink as DefaultLink, useLocation} from "react-router-dom";
import {Navigation} from "components/Navigation";
import {Logo} from "components/Header/Logo";
import {ReactComponent as InfoIcon} from "assets/images/icons/info.svg";
import {Flexible} from "components/Common";

const HeaderContainer = styled.header`
	position: fixed;
	display: flex;
	align-items: center;
	z-index: 10;
	top: 0;
	left: 0;
	width: 100%;
	padding: 6px 10px 6px 8px;
	color: ${({theme}) => theme.text.primary};
	background-color: #001fd6;

	@media (min-width: 769px) {
		padding: 40px 75px 32px 45px;
	}
`;

const NoAuthHeader = styled(HeaderContainer)<{$isBlueHeader?: boolean}>`
	background-color: ${({$isBlueHeader, theme}) =>
		$isBlueHeader ? theme.colors.primary : "transparent"};

	@media (min-width: 769px) {
		padding: ${({$isBlueHeader}) =>
			$isBlueHeader ? "40px 75px 32px 45px" : "10px 85px 10px 45px"};
		justify-content: space-between;
	}
`;

const BurgerMenu = styled(IconButton)`
	&& {
		margin-left: 0;
		margin-right: 5px;

		svg {
			font-size: 28px;
		}

		@media (min-width: 769px) {
			display: none;
		}
	}
`;

const Links = styled.div`
	display: none;
	flex-grow: 1;
	margin-left: 40px;

	& > * {
		margin-right: 20px;
	}

	@media (min-width: 769px) {
		display: flex;

		a {
			&:last-child {
				margin-left: auto;
			}
		}
	}

	@media (min-width: 1000px) {
		margin-left: 110px;

		a {
			margin-right: 45px;
		}
	}
`;

const RightLinks = styled(Flexible)`
	display: none;

	& > * {
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	@media (min-width: 769px) {
		display: block;
	}
`;

const Link = styled(DefaultLink)`
	font-size: 16px;
	text-transform: uppercase;
	font-weight: 700;
	position: relative;
	padding: 9px 4px;
	transition: border-bottom-color 0.3s;
	border-bottom: 1px solid transparent;

	&.active {
		border-bottom: 1px solid ${({theme}) => theme.border.primary};
	}

	&:hover {
		border-bottom-color: ${({theme}) => theme.border.primary};
	}
`;

const DesktopLink = styled(Link)`
	display: none;

	@media (min-width: 769px) {
		display: inline;
	}
`;

const Info = styled(DefaultLink)`
	font-size: 28px;

	* {
		width: 23px;
		height: 21px;
		fill: #ffffff;
	}

	@media (min-width: 769px) {
		display: none;
	}
`;

const IS_OFF_SEASON = process.env.REACT_APP_IS_OFFSEASON || "";

export const Header: React.FC = () => {
	const [isOpenDrawer, setIsOpenDrawer] = useState(false);
	const location = useLocation();
	const isBlueHeader = location.pathname.startsWith("/help");
	const isLeaguePage = location.pathname.startsWith("/league");

	const closeDrawer = useCallback(() => {
		setIsOpenDrawer(false);
	}, [setIsOpenDrawer]);

	const openDrawerHandler = useCallback(() => {
		setIsOpenDrawer(true);
	}, [setIsOpenDrawer]);

	if (useSelector(isLoggedIn))
		return (
			<Fragment>
				<Navigation isOpen={isOpenDrawer} closeHandler={closeDrawer} />
				<HeaderContainer>
					<BurgerMenu
						color="inherit"
						aria-label="open drawer"
						onClick={openDrawerHandler}
						edge="start">
						<MenuIcon />
					</BurgerMenu>
					<Logo />
					<Links>
						<Link to="/predictor">Tips</Link>
						<Link to="/leagues" className={isLeaguePage ? "active" : ""}>
							Leagues
						</Link>
						<Link to="/rankings">Rankings</Link>
						<Link to="/help">Help</Link>
						<Link to="/account">Account</Link>
					</Links>
				</HeaderContainer>
			</Fragment>
		);

	return (
		<Fragment>
			<NoAuthHeader $isBlueHeader={isBlueHeader}>
				<Navigation isOpen={isOpenDrawer} closeHandler={closeDrawer} />
				<BurgerMenu
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={openDrawerHandler}>
					<MenuIcon />
				</BurgerMenu>
				<Logo />
				<RightLinks>
					<DesktopLink to="/help">Help</DesktopLink>
					<Info to="/help">
						<InfoIcon />
					</Info>
					{!IS_OFF_SEASON && <Link to="/login">Log in</Link>}
				</RightLinks>
			</NoAuthHeader>
		</Fragment>
	);
};
