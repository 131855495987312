import {IStore} from "modules/types/store";
import {createSelector} from "reselect";

export const getErrorState = (store: IStore) => store.error;
export const getErrorModalState = createSelector(getErrorState, ({message, title}) => ({
	message,
	title,
}));

export const getModalState = (store: IStore) => store.modal;
export const getInfoModalState = createSelector(getModalState, ({message, title}) => ({
	message,
	title,
}));

export const getIsOpenSaveTipsModal = createSelector(
	getModalState,
	({showSavedTipsModal}) => showSavedTipsModal
);

export const getIsOpenTnCModal = createSelector(
	getModalState,
	({showTermsModal}) => showTermsModal
);

export const getIsOpenReactivateModal = createSelector(
	getModalState,
	({showReactivateModal}) => showReactivateModal
);

export const getIsOpenAcceptTCModal = createSelector(
	getModalState,
	({showAcceptTCModal}) => showAcceptTCModal
);
