import React, {useEffect} from "react";

export const useClickOutside = (
	ref: React.RefObject<HTMLElement>,
	callback: () => void,
	needSkip: boolean = false
) => {
	const handleClick = (e: Event) => {
		if (ref.current && !ref.current.contains(e.target as Node) && !needSkip) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};
