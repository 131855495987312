import styled from "styled-components";
import bg from "assets/images/bg2024.jpeg";
import bgMobile from "assets/images/bgMobile.jpg";

export const Wrapper = styled.div`
	text-align: center;
	display: flex;
	flex-flow: column;
	align-items: center;
	background-image: url(${bgMobile});
	background-position: center top;
	background-size: 100% auto;

	@media (min-width: 769px) {
		min-height: calc(100vh - 340px);
		background-image: url(${bg});
	}
`;
