import React from "react";
import styled from "styled-components";
import productLogoSvg from "assets/images/logoRegistration2024.png";
import whiteProductLogo from "assets/images/whiteProductLogo.svg";
import desktopProductLogo from "assets/images/logo2024client.png";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 25px;

	img {
		max-width: 250px;

		&:first-child {
			margin-bottom: 11px;
		}
	}

	@media (min-width: 769px) {
		margin-bottom: 45px;

		img {
			max-width: 320px;
		}
	}
`;

const ProductLogo = styled.div<{$isLanding?: boolean}>`
	background-image: url(${({$isLanding}) => ($isLanding ? whiteProductLogo : productLogoSvg)});
	background-repeat: no-repeat;
	background-size: contain;
	background-position-x: center;
	width: 250px;
	height: ${({$isLanding}) => ($isLanding ? 115 : 90)}px;
	margin-bottom: 5px;

	@media (min-width: 769px) {
		width: 320px;
		height: 150px;
		background-image: url(${desktopProductLogo});
	}
`;

interface IBanner {
	isLanding?: boolean;
}

export const Banner: React.FC<IBanner> = ({isLanding}) => (
	<Wrapper>
		<ProductLogo $isLanding={isLanding} />
	</Wrapper>
);

export * from "./LeaderBoard";
