import React, {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isSessionChecked} from "modules/selectors";
import {PagePreloader} from "components/Preloader";
import {requestCurrentUser} from "modules/actions/user";
import {userGeoLocationAds} from "modules/actions/ads";

export const Session: React.FC = ({children}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestCurrentUser());
	}, [dispatch]);

	useEffect(() => {
		dispatch(userGeoLocationAds());
	}, [dispatch]);

	return useSelector(isSessionChecked) ? <Fragment>{children}</Fragment> : <PagePreloader />;
};
