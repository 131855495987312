import {IMergedTournament} from "modules/types";
import React, {useCallback, useEffect, useState} from "react";
import {debounce, toInteger} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {getPredictionsByTournamentId} from "modules/selectors";
import {updateMarginPick, updateSavePickButtonState} from "modules/actions";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import {SaveButtonType, TournamentStatusType} from "modules";

const MarginSectionTitle = styled.h2`
	font-size: 11px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 13px;
	@media (min-width: 769px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const MarginSection = styled.div`
	text-align: center;

	> div {
		margin: 10px auto;
		height: 40px;
		width: 180px;
		border-radius: 4px;
		background-color: #0f006e;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 22px;
		letter-spacing: 0;
		line-height: 27px;

		button {
			width: 40px;
			height: 40px;
			background: #062db0;
			color: #fff;
			outline: none;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;

			&:disabled {
				cursor: default;
				opacity: 0.5;
			}
		}
	}
`;

const MarginResultContent = styled.div<{isCorrect?: boolean}>`
	text-align: center;
	margin: 20px auto;

	p {
		color: #000000;
		font-size: 14px;
		line-height: 27px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 0;
		max-width: 300px;
		width: 100%;
		margin: 12px auto 0;
		background-color: ${({isCorrect}) =>
			isCorrect !== undefined && (isCorrect ? "#e4f4dc" : "#fee2df")};

		span {
			font-weight: 700;
			font-size: 22px;
			line-height: 27px;
			margin-left: 2px;

			&:first-child {
				margin-right: 15px;
			}
		}
	}
`;

export const MarginInput = styled.input`
	height: 40px;
	width: 100px;
	background-color: transparent;
	text-align: center;
	font-size: 22px;
	color: #fff;
	border: none;
	/* Chrome, Safari, Edge, Opera */

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */

	&[type="number"] {
		-moz-appearance: textfield;
	}
`;

const MIN_MARGIN = 0;
const MAX_MARGIN = 100;

interface IMarginResultPlaing {
	margin: number;
}

const MarginResultPlaing: React.FC<IMarginResultPlaing> = ({margin}) => (
	<MarginResultContent>
		<MarginSectionTitle>What will the margin be in this match?</MarginSectionTitle>
		<p>
			Prediction - <span> {margin}</span>
		</p>
	</MarginResultContent>
);

interface IMarginProps {
	tournament: IMergedTournament;
}

export const MarginResult: React.FC<IMarginProps> = ({tournament}) => {
	const {homeScore, awayScore, prediction = {isCorrect: false, margin: 0}, status} = tournament;
	const {isCorrect, margin} = prediction;

	if (status === TournamentStatusType.Playing) {
		const numMargin = margin ?? 0;

		return <MarginResultPlaing margin={numMargin} />;
	}
	const numHomeScore = homeScore ?? 0;
	const numAwayScore = awayScore ?? 0;
	const realMargin =
		numHomeScore >= numAwayScore ? numHomeScore - numAwayScore : numAwayScore - numHomeScore;

	return (
		<MarginResultContent isCorrect={Boolean(isCorrect)}>
			<MarginSectionTitle>What will the margin be in this match?</MarginSectionTitle>
			<p>
				Prediction - <span> {margin}</span>
				Result - <span> {realMargin}</span>
			</p>
		</MarginResultContent>
	);
};

export const MarginGame: React.FC<IMarginProps> = ({tournament}) => {
	const dispatch = useDispatch();
	const predictions = useSelector(getPredictionsByTournamentId);
	const {
		id,
		prediction = {margin: 0, tournament: 0, squad: 0},
		status,
	}: IMergedTournament = tournament;
	const {margin = 0, squad} = prediction;
	const isDisabled = status !== TournamentStatusType.Scheduled;
	const [currentMargin, setMargin] = useState(margin ?? 0);

	useEffect(() => {
		setMargin(margin ?? 0);
	}, [margin]);

	const sendUserMargin = () => {
		if (margin === currentMargin) {
			return;
		}
		dispatch(
			updateMarginPick({
				...predictions,
				[id]: {
					squad: squad || 0,
					tournament: id,
					margin: currentMargin,
				},
			})
		);

		if (squad) {
			dispatch(updateSavePickButtonState(SaveButtonType.Enabled));
		}
	};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const delayedQuery = useCallback(debounce(sendUserMargin, 500), [margin, currentMargin]);
	useEffect(() => {
		delayedQuery();

		// Cancel the debounce on useEffect cleanup.
		// eslint-disable-next-line @typescript-eslint/unbound-method
		return delayedQuery.cancel;
	}, [delayedQuery, margin, currentMargin]);

	const increaseMargin = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setMargin(currentMargin + 1);
	};

	const decreaseMargin = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		e.preventDefault();

		const newMargin = currentMargin - 1;

		if (newMargin >= 0) {
			setMargin(newMargin);
		}
	};

	const changeMargin = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const {value} = e.currentTarget;
		let newMargin = toInteger(value);
		if (isNaN(newMargin)) {
			return;
		}

		if (newMargin < MIN_MARGIN) {
			newMargin = MIN_MARGIN;
		}

		if (newMargin > MAX_MARGIN) {
			newMargin = MAX_MARGIN;
		}

		setMargin(newMargin);
	};

	const decreaseDisabled = isDisabled || currentMargin === MIN_MARGIN;
	const increaseDisabled = isDisabled || currentMargin === MAX_MARGIN;

	return (
		<MarginSection>
			<MarginSectionTitle>What will the margin be in this match?</MarginSectionTitle>
			<div>
				<button onClick={decreaseMargin} disabled={decreaseDisabled}>
					<RemoveIcon />
				</button>

				<div>
					<MarginInput
						aria-label="Change Margin"
						name="selected-margin"
						type="text"
						inputMode="numeric"
						value={currentMargin}
						onChange={changeMargin}
						disabled={isDisabled}
					/>
				</div>
				<button onClick={increaseMargin} disabled={increaseDisabled}>
					<AddIcon />
				</button>
			</div>
		</MarginSection>
	);
};
