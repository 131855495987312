import {createAction} from "redux-act";
import {IRanking} from "modules/reducers";

export interface IGetRankingsPayload {
	search?: string;
	round?: number;
	page?: number;
	limit?: number;
	league?: number;
}

export interface IGetRankingsSuccess {
	rankings: IRanking[];
	nextPage: boolean;
}

export const getRankings = createAction<IGetRankingsPayload>();
export const getRankingsSuccess = createAction<IGetRankingsSuccess>();
export const getRankingsFailed = createAction();

export const clearRankings = createAction();
