import styled from "styled-components";

export const LoadMoreButton = styled.div`
	margin: 20px 20px 40px;

	@media (min-width: 769px) {
		max-width: 260px;
		margin: 20px auto 40px;
	}

	@media (min-width: 1025px) {
		margin: 30px auto 75px;
	}
`;
