import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {SaveButtonType, TournamentStatusType} from "modules/enums";
import {filter, memoize, size} from "lodash";
import {IRound} from "modules/actions";
import {getRoundStatsById} from "modules/selectors/jsons";

export const getPredictionsByTournamentId = (state: IStore) => state.game.predictionsById;
export const getGameRequestJsonState = (state: IStore) => state.game.dataRequestJsonState;
export const getGameRequestState = (store: IStore) => store.game.dataRequestState;
export const getGameBar = (state: IStore) => state.game.snapshot;

export const getGameBarData = createSelector(getGameBar, (snapshot) => ({
	...snapshot,
	maxRank: snapshot.maxRank || 0,
	overallRank: snapshot.overallRank || "NA",
	roundPoints: snapshot.roundPoints || 0,
	maxRoundPoints: snapshot.maxRoundPoints || 0,
	overallPoints: snapshot.overallPoints || 0,
	maxOverallPoints: snapshot.maxOverallPoints || 0,
}));

export const getTournamentWithPredictionsSelector = createSelector(
	getPredictionsByTournamentId,
	getRoundStatsById,
	(predictions, roundStats) =>
		memoize((round?: IRound) => {
			if (!round) {
				return [];
			}

			return round.tournaments.map((tournament) => {
				const prediction = predictions[tournament.id];
				const roundStat = roundStats[tournament.id];

				return {
					...tournament,
					prediction,
					roundStat,
				};
			});
		})
);

export const isPickSaveDisabled = (state: IStore) =>
	state.game.pickState !== SaveButtonType.Enabled;
export const getPickSaveButtonState = (state: IStore) => state.game.pickState;

export const isAllSelectedSelector = createSelector(getPredictionsByTournamentId, (predictions) =>
	memoize((round?: IRound) => {
		if (!round) {
			return [];
		}

		const pickedOrLocked = filter(round.tournaments, (tournament) => {
			const prediction = predictions[tournament.id];

			return prediction || tournament.status !== TournamentStatusType.Scheduled;
		});

		return size(pickedOrLocked) === size(round.tournaments);
	})
);

export const isNavBlocking = (state: IStore) => {
	return state.game.pickState === SaveButtonType.Enabled;
};
