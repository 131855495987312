import styled from "styled-components";
import {StyledTableRow as TableRow} from "./LeaguesTable";
import DefaultTableHead from "@mui/material/TableHead";

export const TableHead = styled(DefaultTableHead)`
	height: 50px;
`;

export const StyledTableRow = styled(TableRow)<{$isMe: boolean}>`
	&&&& {
		&.h-73 {
			height: 73px;
		}

		& > * {
			background-color: ${({$isMe}) => ($isMe ? "#062db0" : "#ffffff")};
			color: ${({$isMe, theme}) => ($isMe ? "#ffffff" : theme.table.body.primaryText)};
		}

		&:hover {
			& > * {
				background-color: ${({$isMe}) => ($isMe ? "#062ca7" : "#f1f1f1")};
			}
		}
	}
`;

export const AltText = styled.div`
	padding: 0 20px;
	margin-top: 20px;
	font-weight: 700;
	text-align: center;

	@media (min-width: 769px) {
		margin-top: 50px;
	}
`;
