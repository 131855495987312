import {createSelector} from "reselect";
import {IStore} from "modules/types/store";
import {RequestState} from "modules/enums";

export const getUserState = ({user}: IStore) => user;

export const isSessionChecked = createSelector(
	getUserState,
	({sessionCheckedState}) => sessionCheckedState === RequestState.Received
);

export const getUser = createSelector(getUserState, ({user}) => user);
export const getUserError = createSelector(getUserState, ({error}) => error);
export const getUserTeamNameError = createSelector(
	getUserState,
	({teamNameError}) => teamNameError
);
export const isLoggedIn = createSelector(getUser, (user) => Boolean(user));

export const getPreregisterState = createSelector(getUserState, (user) => user.preregisterSuccess);

export const getRecoveryPasswordState = createSelector(
	getUserState,
	({passwordReset}) => passwordReset
);
