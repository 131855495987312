import {userGeoLocationAdsSuccess} from "modules/actions/ads";
import {RequestState} from "modules/enums";
import {createReducer} from "redux-act";

export interface IUserGeoLocationAds {
	user: {
		location: string;
	};
	ads: IAds;
}
export interface IAds {
	mrec: {
		name: string;
		media_url: string;
		link: string;
		desktopImage: string;
		mobileImage: string;
	};
	leaderboard: {
		name: string;
		media_url: string;
		link: string;
		desktopImage: string;
		mobileImage: string;
	};
	landingPage: {
		name: string;
		media_url: string;
		link: string;
		desktopImage: string;
		mobileImage: string;
	};
}

export interface IGeoLocationADS {
	geoLocationAds: IUserGeoLocationAds;
}
const defaultState: IGeoLocationADS = {
	geoLocationAds: {
		user: {
			location: "AU",
		},
		ads: {
			mrec: {
				name: "",
				media_url: "",
				link: "",
				desktopImage: "",
				mobileImage: "",
			},
			leaderboard: {
				name: "",
				media_url: "",
				link: "",
				desktopImage: "",
				mobileImage: "",
			},
			landingPage: {
				name: "",
				media_url: "",
				link: "",
				desktopImage: "",
				mobileImage: "",
			},
		},
	},
};

const onReceiveUserAds = (state: IGeoLocationADS, payload: IUserGeoLocationAds) => ({
	...state,
	geoLocationAds: payload,
	error: undefined,
	requestState: RequestState.Received,
});

export const geoLocationAds = createReducer<IGeoLocationADS>({}, defaultState).on(
	userGeoLocationAdsSuccess,
	onReceiveUserAds
);
