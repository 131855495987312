import {IStore} from "modules/types/store";
import {createSelector} from "reselect";
import {IRankingsReducer} from "modules/reducers";

export const getRankingsState = (store: IStore) => store.rankings;
export const getRankingsRequestState = createSelector(
	getRankingsState,
	(state: IRankingsReducer) => state.requestState
);
export const getRankingsList = createSelector(
	getRankingsState,
	(state: IRankingsReducer) => state.rankings
);
