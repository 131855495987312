import {createReducer} from "redux-act";
import {RequestState} from "modules/enums";
import {ILeague} from "modules/reducers";
import {
	clearLeagueRankings,
	clearLeagueState,
	deleteLeague,
	deleteLeagueFailed,
	deleteLeagueSuccess,
	deleteLeagueUser,
	deleteLeagueUserFailed,
	deleteLeagueUserSuccess,
	getLeague,
	getLeagueFailed,
	getLeagueRankings,
	getLeagueRankingsFailed,
	getLeagueRankingsSuccess,
	getLeagueSuccess,
	getLeagueUsers,
	getLeagueUsersFailed,
	getLeagueUsersSuccess,
	IGetLeague,
	IGetUsersSuccess,
	inviteUsersToLeague,
	inviteUsersToLeagueFailed,
	inviteUsersToLeagueSuccess,
	IRemoveLeagueUserPayload,
	leaveLeague,
	leaveLeagueFailed,
	leaveLeagueSuccess,
	updateLeague,
	updateLeagueFailed,
	updateLeagueSuccess,
} from "modules/actions/league";
import {IGetRankingsSuccess} from "modules/actions";

export interface ILeagueUser {
	id: number;
	username: string;
}

export interface IRanking {
	userId: number;
	username: string;
	firstName: string;
	lastName: string;
	totalPoints: number | null;
	rank: number | null;
	points: number | null;
	margin: string;
}

export interface ILeagueReducer {
	league: ILeague | null;
	users: ILeagueUser[];
	usersDeleteRequestState: {
		[code: string]: RequestState | undefined;
	};
	rankings: IRanking[];
	updateLeagueRequestState: RequestState;
	deleteLeagueRequestState: RequestState;
	leaveLeagueRequestState: RequestState;
	inviteRequestState: RequestState;
	requestState: RequestState;
	rankingsRequestState: RequestState;
	getUsersRequestState: RequestState;
	needRedirect: boolean;
	usersNextPage: boolean;
	rankingsNextPage: boolean;
}

const initialState: ILeagueReducer = {
	league: null,
	users: [],
	usersDeleteRequestState: {},
	rankings: [],
	requestState: RequestState.IDLE,
	inviteRequestState: RequestState.IDLE,
	rankingsRequestState: RequestState.IDLE,
	updateLeagueRequestState: RequestState.IDLE,
	deleteLeagueRequestState: RequestState.IDLE,
	leaveLeagueRequestState: RequestState.IDLE,
	getUsersRequestState: RequestState.IDLE,
	needRedirect: false,
	usersNextPage: true,
	rankingsNextPage: false,
};

const onGetLeague = (state: ILeagueReducer) => ({
	...state,
	requestState: RequestState.Requested,
});

const onGetLeagueSuccess = (state: ILeagueReducer, payload: IGetLeague) => ({
	...state,
	league: payload.league,
	needRedirect: !payload.isJoined,
	requestState: RequestState.Received,
});

const onGetLeagueFailed = (state: ILeagueReducer) => ({
	...state,
	requestState: RequestState.Received,
	needRedirect: true,
});

const onGetLeagueRankings = (state: ILeagueReducer) => ({
	...state,
	rankingsRequestState: RequestState.Requested,
});

const onGetLeagueRankingsSuccess = (state: ILeagueReducer, payload: IGetRankingsSuccess) => ({
	...state,
	rankings: [...state.rankings, ...payload.rankings],
	rankingsNextPage: payload.nextPage,
	rankingsRequestState: RequestState.Received,
});

const onGetLeagueRankingsFailed = (state: ILeagueReducer) => ({
	...state,
	rankingsRequestState: RequestState.Received,
});

const onGetLeagueUsers = (state: ILeagueReducer) => ({
	...state,
	getUsersRequestState: RequestState.Requested,
});

const onGetLeagueUsersSuccess = (state: ILeagueReducer, payload: IGetUsersSuccess) => ({
	...state,
	users: [...state.users, ...payload.users],
	usersNextPage: payload.nextPage,
	getUsersRequestState: RequestState.Received,
});

const onGetLeagueUsersFailed = (state: ILeagueReducer) => ({
	...state,
	getUsersRequestState: RequestState.Received,
});

const onUpdateLeague = (state: ILeagueReducer) => ({
	...state,
	updateLeagueRequestState: RequestState.Requested,
});

const onUpdateLeagueSuccess = (state: ILeagueReducer, league: ILeague) => ({
	...state,
	league,
	updateLeagueRequestState: RequestState.Received,
});

const onUpdateLeagueFailed = (state: ILeagueReducer) => ({
	...state,
	updateLeagueRequestState: RequestState.Received,
});

const onInviteUsersToLeague = (state: ILeagueReducer) => ({
	...state,
	inviteRequestState: RequestState.Requested,
});

const onInviteUsersToLeagueSuccess = (state: ILeagueReducer) => ({
	...state,
	inviteRequestState: RequestState.Received,
});

const onInviteUsersToLeagueFailed = (state: ILeagueReducer) => ({
	...state,
	inviteRequestState: RequestState.Received,
});

const onDeleteLeague = (state: ILeagueReducer) => ({
	...state,
	deleteLeagueRequestState: RequestState.Requested,
});

const onDeleteLeagueSuccess = (state: ILeagueReducer) => ({
	...state,
	deleteLeagueRequestState: RequestState.Received,
	needRedirect: true,
});

const onDeleteLeagueFailed = (state: ILeagueReducer) => ({
	...state,
	deleteLeagueRequestState: RequestState.Received,
});

const onLeaveLeague = (state: ILeagueReducer) => ({
	...state,
	leaveLeagueRequestState: RequestState.Requested,
});

const onLeaveLeagueSuccess = (state: ILeagueReducer) => ({
	...state,
	needRedirect: true,
	leaveLeagueRequestState: RequestState.Received,
});

const onLeaveLeagueFailed = (state: ILeagueReducer) => ({
	...state,
	leaveLeagueRequestState: RequestState.Received,
});

const onDeleteLeagueUser = (state: ILeagueReducer, payload: IRemoveLeagueUserPayload) => ({
	...state,
	usersDeleteRequestState: {
		...state.usersDeleteRequestState,
		[payload.userId]: RequestState.Requested,
	},
});

const onDeleteLeagueUserSuccess = (state: ILeagueReducer, userId: number) => ({
	...state,
	users: state.users.filter((user) => user.id !== userId),
	usersDeleteRequestState: {
		...state.usersDeleteRequestState,
		[userId]: undefined,
	},
});

const onDeleteLeagueUserFailed = (state: ILeagueReducer, userId: string) => ({
	...state,
	usersDeleteRequestState: {
		...state.usersDeleteRequestState,
		[userId]: RequestState.IDLE,
	},
});

const onClearLeagueState = () => {
	return {
		...initialState,
	};
};

const onClearLeagueRankings = (state: ILeagueReducer) => ({
	...state,
	rankings: [],
	rankingsNextPage: false,
});

export const league = createReducer<ILeagueReducer>({}, initialState)
	.on(getLeague, onGetLeague)
	.on(getLeagueSuccess, onGetLeagueSuccess)
	.on(getLeagueFailed, onGetLeagueFailed)

	.on(getLeagueRankings, onGetLeagueRankings)
	.on(getLeagueRankingsSuccess, onGetLeagueRankingsSuccess)
	.on(getLeagueRankingsFailed, onGetLeagueRankingsFailed)

	.on(getLeagueUsers, onGetLeagueUsers)
	.on(getLeagueUsersSuccess, onGetLeagueUsersSuccess)
	.on(getLeagueUsersFailed, onGetLeagueUsersFailed)

	.on(updateLeague, onUpdateLeague)
	.on(updateLeagueSuccess, onUpdateLeagueSuccess)
	.on(updateLeagueFailed, onUpdateLeagueFailed)
	/**
	 * Invite Users To League
	 * */
	.on(inviteUsersToLeague, onInviteUsersToLeague)
	.on(inviteUsersToLeagueSuccess, onInviteUsersToLeagueSuccess)
	.on(inviteUsersToLeagueFailed, onInviteUsersToLeagueFailed)

	.on(deleteLeague, onDeleteLeague)
	.on(deleteLeagueSuccess, onDeleteLeagueSuccess)
	.on(deleteLeagueFailed, onDeleteLeagueFailed)

	.on(leaveLeague, onLeaveLeague)
	.on(leaveLeagueSuccess, onLeaveLeagueSuccess)
	.on(leaveLeagueFailed, onLeaveLeagueFailed)

	.on(deleteLeagueUser, onDeleteLeagueUser)
	.on(deleteLeagueUserSuccess, onDeleteLeagueUserSuccess)
	.on(deleteLeagueUserFailed, onDeleteLeagueUserFailed)

	.on(clearLeagueRankings, onClearLeagueRankings)
	.on(clearLeagueState, onClearLeagueState);
