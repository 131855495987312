export enum ShareNet {
	Facebook = "fb",
	Twitter = "tw",
}

export enum ShareSource {
	General = "general",
}

export enum RequestState {
	IDLE,
	Requested,
	Received,
}

export enum SaveButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum ButtonType {
	Disabled,
	Saving,
	Saved,
	Enabled,
}

export enum PrivacyType {
	Private = "private",
	Public = "public",
}

export enum RoundStatusType {
	Active = "active",
	Complete = "complete",
	Scheduled = "scheduled",
}

export enum TournamentStatusType {
	Playing = "active",
	Complete = "complete",
	Scheduled = "scheduled",
}

export enum NavigationSide {
	Prev = "prev",
	Next = "next",
}

export enum CreateLeaguesTab {
	FIRST,
	SECOND,
}

export enum SectionType {
	LEAGUE = "league",
	INVITE_FRIENDS = "invite-friends",
}

export enum ShareType {
	FB,
	TW,
	MOBILE,
}
