import styled from "styled-components";
import {IconButton} from "@mui/material";

export const ModalContainer = styled.div`
	background: rgba(0, 0, 0, 0.5);
	left: 0;
	top: 0;
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 50;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Modal = styled.div`
	width: 100%;
	background: #fff;
	margin: 0 10%;
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
	z-index: 51;
	position: relative;

	@media (min-width: 769px) {
		height: auto;
		max-width: 570px;
		min-height: 200px;
	}
`;

export const CloseButton = styled(IconButton)`
	&& {
		padding: 10px;
		position: absolute;
		right: 0;
		top: 0;

		svg {
			width: 16px;

			@media (min-width: 769px) {
				width: 20px;
			}
		}
	}
`;

export const SuccessImg = styled.div`
	margin: 25px 0;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg {
		width: 25px;
		height: 20px;
	}

	@media (min-width: 769px) {
		margin-top: 36px;
		width: 60px;
		height: 60px;

		svg {
			width: 33px;
			height: 27px;
		}
	}
`;

export const Img = styled.img`
	margin: 20px 0 25px;

	@media (max-width: 768px) {
		width: 40px;
	}
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #343232;
	text-align: center;
	width: 100%;
	padding: 16px;

	@media (min-width: 769px) {
		padding: 20px;
	}
`;

export const Title = styled.h4`
	font-family: Kino, sans-serif;
	letter-spacing: 0.83px;
	font-size: 22px;
	margin-bottom: 16px;
	line-height: 22px;

	@media (min-width: 769px) {
		margin-bottom: 25px;
		font-size: 30px;
	}
`;

export const Text = styled.div`
	margin-bottom: 30px;
	line-height: 17px;
	font-size: 14px;

	strong {
		font-weight: 700;
	}

	@media (min-width: 769px) {
		font-size: 16px;
	}
`;

export const ButtonsBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 260px;
	width: 100%;
	margin-bottom: 20px;

	button + button {
		margin-left: 20px;
	}
`;
