import React, {useCallback, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	SuccessImg,
	Modal,
	ModalContainer,
	CloseButton,
	ButtonsBox,
	Text,
	Title,
	ModalContent,
} from "./common";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";
import {PrimaryButton} from "components/Buttons";
import {getInfoModalState} from "modules/selectors";
import {hideInfoModal} from "modules/actions/modals";
import {ReactComponent as BlackCheck} from "assets/images/icons/blackCheck.svg";
import {useClickOutside} from "modules/utils/hooks/useClickOutside";

export const InfoModal: React.FC = () => {
	const ref = useRef(null);
	const dispatch = useDispatch();
	const {title, message} = useSelector(getInfoModalState);

	const closeHandler = useCallback(() => {
		dispatch(hideInfoModal());
	}, [dispatch]);

	useClickOutside(ref, closeHandler);

	if (!message) {
		return null;
	}

	return (
		<ModalContainer>
			<Modal ref={ref}>
				<CloseButton id="btn-close-error-modal">
					<CloseIcon onClick={closeHandler} />
				</CloseButton>
				<ModalContent>
					<SuccessImg>
						<BlackCheck />
					</SuccessImg>
					{title && <Title>{title}</Title>}
					<Text>{message}</Text>
					<ButtonsBox>
						<PrimaryButton onClick={closeHandler}>OK</PrimaryButton>
					</ButtonsBox>
				</ModalContent>
			</Modal>
		</ModalContainer>
	);
};
