import React from "react";
import styled from "styled-components";

const Img = styled.img`
	max-width: 320px;
	margin-bottom: 30px;

	@media (min-width: 769px) {
		max-width: 728px;
		margin-bottom: 70px;
	}
`;

interface ILeaderBoardProps {
	img?: string;
}
export const LeaderBoard: React.FC<ILeaderBoardProps> = (props: ILeaderBoardProps) => (
	<Img src={props.img} />
);

export const PrivatePageLeaderBoard = styled.a<{img: string}>`
	background-image: url(${(props) => props.img});
	max-width: 320px;
	width: 100%;
	height: 50px;
	background-repeat: no-repeat;
	background-size: contain;

	@media (min-width: 769px) {
		margin-top: 20px;
		max-width: 728px;
		height: 90px;
		background-image: url(${(props) => props.img});
	}
`;
