import {createAction} from "redux-act";
import {IPrediction, ISnapshot} from "modules/reducers";
import {IRound, ITournament} from "modules/actions";
import {IDictionary} from "modules/types";
import {SaveButtonType} from "modules/enums";

export const requestGameData = createAction<{roundId?: number}>();
export const requestGameDataSuccess = createAction();
export const requestGameDataFailed = createAction();

export const requestGameJsonData = createAction();
export const requestGameJsonDataSuccess = createAction();
export const requestGameJsonDataFailed = createAction();

export const requestPredictionByRoundId = createAction<number>();
export const requestPredictionByRoundIdSuccess = createAction<IDictionary<IPrediction>>();
export const requestPredictionByRoundIdFailed = createAction();

export const updatePick = createAction<IDictionary<IPrediction>>();
export const updateMarginPick = createAction<IDictionary<IPrediction>>();

export const postAutoPick = createAction<IRound>();
export const postAutoPickSuccess = createAction<IDictionary<IPrediction>>();
export const postAutoPickFailed = createAction();

export const postSavePicks = createAction<IRound>();
export const postSavePicksSuccess = createAction<IDictionary<IPrediction>>();
export const postSavePicksFailed = createAction();

export const updateSavePickButtonState = createAction<SaveButtonType>();

export interface IPredictionsPayload {
	predictions: {tournament: number; margin: number; squad: number}[];
}

export const requestSnapshotByRoundId = createAction<number>();
export const requestSnapshotByRoundIdSuccess = createAction<ISnapshot>();
export const requestSnapshotByRoundIdFailed = createAction();

export const clearPicks = createAction<ITournament[]>();
