import {usePrevious} from "modules/utils";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export const useScrollToTop = () => {
	const {pathname} = useLocation();
	const previousPathname = usePrevious(pathname);

	useEffect(() => {
		if (pathname === previousPathname) {
			return;
		}

		window.scrollTo(0, 0);
	}, [pathname, previousPathname]);
};
