import {createReducer} from "redux-act";
import {clearGlobalError, IShowGlobalError, showGlobalError} from "modules/actions";

interface IErrorReducer {
	title?: string;
	message: string;
}

const initialState: IErrorReducer = {
	title: "",
	message: "",
};

const onShowGlobalError = (state: IErrorReducer, payload: IShowGlobalError) => ({
	...state,
	message: payload.message,
	title: payload.title || "",
});

const onClearGlobalError = () => initialState;

export const error = createReducer<IErrorReducer>({}, initialState)
	.on(showGlobalError, onShowGlobalError)
	.on(clearGlobalError, onClearGlobalError);
