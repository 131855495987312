import React from "react";
import {Link, Outlet} from "react-router-dom";
import styled from "styled-components";
import {Container as BaseContainer, ExternalLink} from "components/Common";
import {Wrapper as DefaultWrapper} from "components/Layouts/Wrapper";
import CloseIcon from "@mui/icons-material/Close";
import {IconButton} from "@mui/material";
import {Banner, LeaderBoard, PrivatePageLeaderBoard, OnlyDesktop} from "components";
import {useSelector} from "react-redux";
import {getUserAds} from "modules/selectors";
import {APP_URL} from "modules";
import {useMediaQuery} from "modules/utils";
import {HOCGameData} from "components/HOCGameData";

const Wrapper = styled(DefaultWrapper)`
	@media (max-width: 768px) {
		background: #ffffff;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 20;
		width: 100%;
	}
`;

export const BaseLayout = styled.div`
	margin-top: 70px;
	width: 100%;

	@media (min-width: 769px) {
		padding: 0 20px;
		margin-top: 130px;
		min-height: 700px;
	}
`;

const PrivateWrapper = styled(BaseLayout)``;

const Container = styled.div`
	margin: 80px 20px 70px;
	max-width: 320px;

	@media (min-width: 769px) {
		margin-top: 130px;
		max-width: none;
	}
`;

const Form = styled(BaseContainer)`
	margin-top: 60px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fff;
	border-radius: 4px;
	max-width: 375px;

	a:hover {
		text-decoration: underline;
	}

	@media (min-width: 769px) {
		margin-top: auto;
		padding: 37px 60px;
		max-width: 420px;
	}
`;

export const PrivateContainer = styled(BaseContainer)`
	display: flex;
	flex-flow: column;
	align-items: center;
	padding: 0;
	@media (min-width: 769px) {
		min-height: calc(100vh - 461px);
	}
`;

const CloseButton = styled(IconButton)`
	&& {
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 20;
		padding: 10px;
		color: #000000;

		a {
			height: 24px;
			width: 24px;
		}

		@media (min-width: 769px) {
			display: none;
		}
	}
`;

// const Title = styled.h1`
// 	font-family: Kino, sans-serif;
// 	font-size: 36px;
// 	font-weight: 400;
// 	line-height: 43px;
// 	color: #fff;
// 	opacity: 0.9;
// 	margin: 30px 0;
// `;

// const Description = styled.p`
// 	font-family: "Proxima Nova", sans-serif;
// 	font-size: 18px;
// 	font-weight: 400;
// 	line-height: 24px;
// 	color: #fff;
// 	&.bold {
// 		font-weight: 700;
// 	}
// 	&.last {
// 		margin-bottom: 30px;
// 	}
// `;

export const OffseasonLayout: React.FC = () => (
	<Wrapper>
		<Container>
			<CloseButton>
				<Link to="/">
					<CloseIcon />
				</Link>
			</CloseButton>
			<Banner />
			{/* <Title>
				Thank you for playing the official Super Rugby Pacific tipping competition for
				season 2022!
			</Title>
			<Description className="bold">Congratulations to the winners:</Description>
			<Description>1st place: Jason F - $5,000 AUD.</Description>
			<Description>
				2nd place: Jamie H - Two (2) x 2023 season memberships to their Super Rugby Pacific
				club of choice.
			</Description>
			<Description className="last">
				3rd place: Ray D - Two (2) x platinum tickets to either one (1) All Blacks home game
				in the 2023 season OR Two (2) x platinum tickets to one (1) Wallabies home game in
				the 2023 season.
			</Description> */}

			<Form>
				<Outlet />
			</Form>
		</Container>
	</Wrapper>
);

export const Layout: React.FC = () => {
	const geolocation = useSelector(getUserAds);
	const imgUrl = geolocation.ads.landingPage.desktopImage;
	const img = APP_URL + imgUrl;
	return (
		<Wrapper>
			<Container>
				<CloseButton>
					<Link to="/">
						<CloseIcon />
					</Link>
				</CloseButton>
				<Banner />
				<Form>
					<Outlet />
				</Form>
			</Container>
			<OnlyDesktop>
				<ExternalLink href={geolocation.ads.landingPage.link}>
					<LeaderBoard img={img} />
				</ExternalLink>
			</OnlyDesktop>
		</Wrapper>
	);
};

export const PrivateLandingLayout: React.FC = () => {
	const geolocation = useSelector(getUserAds);
	const isMobile = useMediaQuery(`(max-width: 769px`);
	const imgUrl = isMobile
		? geolocation.ads.leaderboard.mobileImage
		: geolocation.ads.leaderboard.desktopImage;
	const img = APP_URL + imgUrl;
	return (
		<PrivateWrapper>
			<PrivateContainer>
				<PrivatePageLeaderBoard img={img} href={geolocation.ads.leaderboard.link} />
				<HOCGameData>
					<Outlet />
				</HOCGameData>
			</PrivateContainer>
		</PrivateWrapper>
	);
};

export * from "./Content";
export * from "./Wrapper";
