import {call, delay, put} from "typed-redux-saga";
import {
	createLeague,
	createLeagueFailed,
	createLeagueSuccess,
	getLeaguesFailed,
	getLeaguesForJoin,
	getLeaguesForJoinFailed,
	getLeaguesForJoinSuccess,
	getLeaguesSuccess,
	inviteUsersToCreateLeague,
	inviteUsersToCreateLeagueFailed,
	inviteUsersToCreateLeagueSuccess,
	joinLeague,
	joinLeagueFailed,
	joinLeagueSuccess,
	joinLeagueWithRedirect,
	joinLeagueWithRedirectFailed,
	joinLeagueWithRedirectSuccess,
	setEditMode,
	showGlobalError,
	createdUpdateLeague,
	createdUpdateLeagueFailed,
	createdUpdateLeagueSuccess,
	getLeagues,
} from "modules/actions";
import {Api, ApiError} from "modules/utils/Api";

export const getLeaguesSaga = function* ({payload}: ReturnType<typeof getLeagues>) {
	try {
		const result = yield* call(Api.Leagues.get, payload);

		yield* put(getLeaguesSuccess(result.success));
	} catch (err) {
		yield* put(getLeaguesFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const getLeaguesForJoinSaga = function* ({payload}: ReturnType<typeof getLeaguesForJoin>) {
	try {
		if (payload.search) {
			// debounce
			yield delay(300);
		}

		const result = yield* call(Api.Leagues.getLeaguesForJoin, payload);

		yield* put(getLeaguesForJoinSuccess({...result.success}));
	} catch (err) {
		yield* put(getLeaguesForJoinFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const createLeagueSaga = function* ({payload}: ReturnType<typeof createLeague>) {
	try {
		const result = yield* call(Api.League.create, payload);

		yield* put(createLeagueSuccess(result.success.league));
	} catch (err) {
		yield* put(createLeagueFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const inviteUsersToCreateLeagueSaga = function* ({
	payload,
}: ReturnType<typeof inviteUsersToCreateLeague>) {
	try {
		yield* call(Api.League.invite, payload);

		yield* put(inviteUsersToCreateLeagueSuccess());
	} catch (err) {
		yield* put(inviteUsersToCreateLeagueFailed());

		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const createdUpdateLeagueSaga = function* ({
	payload,
}: ReturnType<typeof createdUpdateLeague>) {
	try {
		const result = yield* call(Api.League.update, payload);

		yield* put(createdUpdateLeagueSuccess(result.success.league));
		yield* put(setEditMode(false));
	} catch (err) {
		yield* put(createdUpdateLeagueFailed());
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const joinLeagueSaga = function* ({payload}: ReturnType<typeof joinLeague>) {
	try {
		const result = yield* call(Api.League.join, payload);

		yield* put(joinLeagueSuccess(result.success.league));
	} catch (err) {
		yield* put(joinLeagueFailed(payload));
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};

export const joinLeagueWithRedirectSaga = function* ({
	payload,
}: ReturnType<typeof joinLeagueWithRedirect>) {
	try {
		const result = yield* call(Api.League.join, payload);

		yield* put(joinLeagueWithRedirectSuccess(result.success.league.id.toString()));
	} catch (err) {
		yield* put(joinLeagueWithRedirectFailed());
		if (err instanceof ApiError) {
			yield* put(showGlobalError(err));
		}
	}
};
